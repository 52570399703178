import { Button, Form } from "antd";

function TestTypeButtons({
  isImageConfirmationNeeded,
  setIsImageConfirmationNeeded,
  setSearchQuery,
  setLoadingOrdertest,
}) {
  return (
    <Form.Item
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Button
        className={isImageConfirmationNeeded ? "" : "button-active"}
        type="default"
        size="large"
        onClick={() => {
          setIsImageConfirmationNeeded(false);
          setSearchQuery("");
          setLoadingOrdertest(true);
          setTimeout(() => {
            setLoadingOrdertest(false);
          }, 2000);
        }}
      >
        Tạo chỉ định xét nghiệm
      </Button>
      <Button
        className={isImageConfirmationNeeded ? "button-active" : ""}
        type="default"
        size="large"
        style={{ marginLeft: "1rem" }}
        onClick={() => setIsImageConfirmationNeeded(true)}
      >
        Xét nghiệm bằng phiếu chỉ định
      </Button>
    </Form.Item>
  );
}
export default TestTypeButtons;
