import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "src/services";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { allActions } from "src/redux";

type Props = {
  children: any;
};

const initialState = {};

export const AuthContext = createContext(initialState);

export function AuthProvider(props: Props) {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const token = localStorage.getItem("access_token");
      if (!token) {
        history.push("/auth/login");
        setLoading(false);
        return;
      }
      const settings : any = await api.getSetting();
      dispatch(allActions.setting.saveSetting(settings.data))
      
      try {
        const response: { data: any } = await api.getProfile();
        const { accessToken = null, userData = null } = response?.data;
        if (accessToken && userData) {
          localStorage.setItem("access_token", accessToken);
          dispatch(allActions.user.saveUser(userData));
        } else {
          message.error("Data invalid!");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        history.push("/auth/login");
        console.log("Error", error);
      }
    })();
  }, []);

  const { children } = props;
  return (
    <AuthContext.Provider value={{}}>
      {loading ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingOutlined style={{ fontSize: 30 }} spin />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
