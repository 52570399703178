export const fonts = {
  IBMPlexSans: {
    italic: "IBMPlexSans-Italic",
    regular: "IBMPlexSans-Regular",
    bold: "IBMPlexSans-Bold",
    medium: "IBMPlexSans-Medium",
    semiBold: "IBMPlexSans-SemiBold",
  },
};

export const excel = {
  MedicineImport: require("./excel/MedicineImport.xlsx"),
};

export const images = {
  // qrDemo: require("./images/qr-demo.jpeg"),
  // backgroundLogin: require('./images/background_login.jpg'),
  // logo: require('./images/logo.png'),
  // mdiPassword: require('./images/mdi_password.png'),
  // mdiEmail: require('./images/mdi_email.png'),
  // mdiRegister: require('./images/mdi_register.png'),
  // symbolLogo: require('./images/icons/symbollogoz.png'),
  // iconCalendar: require('./images/icons/iconcalendar.png'),
  // iconCalendarToday: require('./images/icons/ic_outline-calendar-today.png'),
  // iconMessenger: require('./images/icons/iconmessenger.png'),
  // iconXn: require('./images/icons/iconxn.png'),
  // iconMedicine: require('./images/icons/iconmedicine.png'),
  // symbolLogoWhite: require('./images/icons/symbollogoz_white.png'),
  // iconCalendarWhite: require('./images/icons/iconcalendar_white.png'),
  // iconMessengerWhite: require('./images/icons/iconmessenger_white.png'),
  // iconProfileNavFocus: require('./images/icons/icon-profile-nav-focus.png'),
  // iconTagNavi: require('./images/icons/icon-tag-navi.png'),
  // iconProfileNav: require('./images/icons/icon-profile-nav.png'),
  // iconMedicineWhite: require('./images/icons/iconmedicine_white.png'),
  // iconXnWhite: require('./images/icons/iconxn_white.png'),
  // iconCiMenu: require('./images/icons/ci_menu-alt-05.png'),
  // iconCiQr: require('./images/icons/qr.png'),
  // iconCiBell: require('./images/icons/bell_blue.png'),
  // iconParkOutlineSystem: require('./images/icons/icon-park-outline_system.png'),
  // iconMedicalSystem: require('./images/icons/medical.png'),
  // iconQuillSignatureSystem: require('./images/icons/quill_signature.png'),
  // iconMedicalDoctorSystem: require('./images/icons/wpf_medical-doctor.png'),
  // iconDocumentSystem: require('./images/icons/clarity_document-outline-badged.png'),
  // iconBookSystem: require('./images/icons/material-symbols_book-outline.png'),
  // iconTimelineSystem: require('./images/icons/mdi_chart-timeline-variant-shimmer.png'),
  // iconAddNote: require('./images/icons/material-symbols_note-add-rounded.png'),
  // iconEdit: require('./images/icons/material-symbols_edit-outline.png'),
  // iconClose: require('./images/icons/ic_round-close.png'),
  // iconCheck: require('./images/icons/material-symbols_check-small-rounded.png'),
  // iconCalendarBold: require('./images/icons/material-symbols_calendar-month.png'),
  // iconClosePopup: require('./images/icons/icon-close-popup.png'),
  // iconEditPopup: require('./images/icons/icon-edit-popup.png'),
  // iconPhoneCircle: require('./images/icons/icon-phone.png'),
  // iconMessageCircle: require('./images/icons/icon-message.png'),
  // iconSolidStethoscope: require('./images/icons/icon-solid-stethoscope.png'),
  // iconBlueArrowRight: require('./images/icons/icon-arrow-right.png'),
  // iconSolidMedical: require('./images/icons/icon-solid-medical.png'),
  // iconBlueCalendarCheck: require('./images/icons/icon-blue-calendar-check.png'),
  // iconBlueRoundClose: require('./images/icons/icon-blue-round-close.png'),
  // iconBlackRoundClose: require('./images/icons/icon-black-round-close.png'),
  // iconManageSearch: require('./images/icons/icon-manage-search.png'),
  // iconArrowDown: require('./images/icons/icon-arrow-down.png'),
  // iconArrowUp: require('./images/icons/icon-arrow-up.png'),
  // iconWhiteMedicalDoctor: require('./images/icons/icon-white-medical-doctor.png'),
  // iconWhiteQr: require('./images/icons/icon-white-qr.png'),
  // iconRoundedClose: require('./images/icons/icon-solid-rounded-close.png'),
  // iconBlackArrowRight: require('./images/icons/icon-black-arrow-right.png'),
  // iconWhiteAttachment: require('./images/icons/icon-white-attachment.png'),
  // iconGreenCheck: require('./images/icons/icon-green-check.png'),
  // iconAddNewItem: require('./images/icons/icon-add-new-item.png'),
  // iconPhotoCameraRounded: require('./images/icons/icon-photo-camera-back-rounded.png'),
  // iconWhiteTest: require('./images/icons/icon-white-test.png'),
  // iconBarcode: require('./images/icons/icon-barcode.png'),

  // iconArrowRight: require('./images/icons/arrow_right.png'),
  // iconArrowRightHeader: require('./images/icons/arrow_right_2.png'),
  // homeItemVaccineApproval: require('./images/home/chi-dinh-xet-nghiem-2.png'),
  // homeItemStatic: require('./images/home/static.png'),
  // homeItemShorterWorkweek: require('./images/home/shorter_workweek.png'),
  // homeItemHealthCare: require('./images/home/healthcare.png'),
  // homeItemInfluenza: require('./images/home/influenza.png'),
  // homeItemCheck: require('./images/home/check.png'),
  // homeItemStocks: require('./images/home/stocks.png'),
  // homeNewsDemo1: require('./images/home/home-news-1.jpg'),
  // homeNewsDemo2: require('./images/home/home-news-2.jpg'),
  // homeNewsDemo3: require('./images/home/home-news-3.jpg'),
  // homeBackgroundTop: require('./images/home/background_top.png'),
  // homeBackgroundBottom: require('./images/home/background_bottom.png'),
  // homeBackground: require('./images/home/background2.png'),
  // homeBackgroundVector: require('./images/home/background_vector.png'),
  // homeHealthCheck: require('./images/home/health-check-home.png'),
  // homeMyProfile: require('./images/home/my-profile-home.png'),
  // homePrescription: require('./images/home/prescription-home.png'),
  // homeBookOutline: require('./images/home/home-book-outline.png'),
  // homeMdiVirus: require('./images/home/home-mdi-virus.png'),
  // homeMedicalDoctor: require('./images/home/home-medical-doctor.png'),
  // homeContactCalendar: require('./images/home/home-round-perm-contact-calendar.png'),
  // homeOutlineSystem: require('./images/home/home-park-outline-system.png'),
  // homeMedia: require('./images/home/home-media.png'),
  // homeHistory: require('./images/home/home-history.png'),
  // homeQr: require('./images/home/home-qr.png'),

  // avatarDemo: require('./images/avatar_demo.png'),
  // iconAddToPhoto: require('./images/icons/material-symbols_add-a-photo.png'),
  // iconDrawerAvatar: require('./images/icons/carbon_user_avatar_filled.png'),
  // iconDrawerQrCode: require('./images/icons/material_symbols_qr_code.png'),
  // iconDrawerFaceId: require('./images/icons/tabler_face_id.png'),
  // iconDrawerBook: require('./images/icons/mdi_book_variant.png'),
  // iconDrawerBell: require('./images/icons/bell.png'),
  // iconDrawerPasswordCheck: require('./images/icons/mdi_password_check.png'),
  // iconDrawerLogout: require('./images/icons/ri_logout_circle_fill.png'),
  // iconDrawerDelete: require('./images/icons/material_symbols_delete.png'),
  // iconFormOutlineCheck: require('./images/icons/ic_outline-check.png'),
  // profileBgPatterm: require('./images/profile/profile-bg-patterm.png'),
  // addImage: require('./images/profile/add-image.png'),
  // qrCode: require('./images/qr-code.png'),
  // iconAddGallery: require('./images/icon-add-gallery.png'),
  // iconShare: require('./images/icon-share.png'),
  // iconLogoutWarning: require('./images/logout-warning.png'),
  // iconRemoveUser: require('./images/modal-remove-user.png'),
  // iconCallSharp: require('./images/icons/icon-call-sharp.png'),
  // iconCheckedCircle: require('./images/icons/icon-checked-circle.png'),
  // iconBaselineLocation: require('./images/icons/icon-baseline-location.png'),
  // iconMaleGender: require('./images/icons/icon-man-gender.png'),
  // iconFemaleGender: require('./images/icons/icon-female.png'),
  // iconCalendarCheck: require('./images/icons/icon-calendar-check.png'),
  // iconGCLS: require('./images/icons/icon-ghi-chu-lam-san.png'),
  // iconAddNewRounded: require('./images/icons/icon-add-new.png'),
  // iconSelectImageRounded: require('./images/icons/icon-select-image.png'),
  // iconAddNew: require('./images/icons/icon-add-new-transparent.png'),
  // iconSelectImage: require('./images/icons/icon-take-picture-transparent.png'),
  // iconMessageRounded: require('./images/icons/icon-message-rounded.png'),
  // iconPhoneRounded: require('./images/icons/icon-phone-rounded.png'),
  // iconMarkTransparent: require('./images/icons/icon-mark-transparent.png'),
  // iconNoteCheck: require('./images/icons/icon-note-check.png'),
  // iconTest: require('./images/icons/icon-test-transparent.png'),
  // iconTestWhite: require('./images/icons/icon-test-white.png'),
  // iconDiagnose: require('./images/icons/icon-diagnose-transparent.png'),
  // iconNoteWritting: require('./images/icons/icon-note-writting.png'),
  // iconPicture: require('./images/icons/icon-picture-transparent.png'),
  // iconPdf: require('./images/icons/icon-pdf.png'),
  // iconCloseBlack: require('./images/icons/icon-close.png'),
  // iconCloseBlackRounded: require('./images/icons/icon-close-rounded.png'),
  // iconFile: require('./images/icons/icon-file.png'),
  // iconFolder: require('./images/icons/icon-folder.png'),
  // iconTrashcanDark: require('./images/icons/icon-trashcan-dark.png'),
  // iconTrashcanLight: require('./images/icons/icon-trashcan-light.png'),
  // iconDeleteWhite: require('./images/icons/icon-delete-white.png'),
  // iconFilter: require('./images/icons/icon-filter.png'),
  // iconArrowRightNavi: require('./images/icons/icon-arrow-right-navi.png'),
  // iconListBulleted: require('./images/icons/icon-list-bulleted.png'),
  // iconFileRounded: require('./images/icons/icon-file-rounded.png'),
  // iconChecked: require('./images/icons/icon-checked.png'),
  // iconUnchecked: require('./images/icons/icon-unchecked.png'),
  // iconDeleteNavi: require('./images/icons/icon-delete-navi.png'),
  // iconEditRounded: require('./images/icons/icon-edit-rounded.png'),
  // iconCoin: require('./images/icons/icon-coin.png'),
  // iconHospitalBuilding: require('./images/icons/icon-hospital-building.png'),
  // iconClock: require('./images/icons/icon-clock.png'),
  // iconQrNavi: require('./images/icons/icon-qr-navi.png'),
  // iconTimePicker: require('./images/icons/icon-clock-time-picker.png'),
  // iconCook: require('./images/icons/icon-cook.png'),
  // iconCoupon: require('./images/icons/icon-coupon.png'),
  // iconBuilding: require('./images/icons/icon-building.png'),
  // iconFaceId: require('./images/icons/icon-face-id.png'),

  // scheduleNote: require('./images/schedule/icon-note-schedule.png'),
  // scheduleDoctor: require('./images/schedule/icon-doctor-schedule.png'),
  // scheduleugs: require('./images/schedule/icon-drugs-schedule.png'),
  // scheduleNoteCheck: require('./images/schedule/icon-note-check-schedule.png'),
  // scheduleVirus: require('./images/schedule/icon-virus-schedule.png'),
  // scheduleDoc: require('./images/schedule/icon-doc-schedule.png'),
  // imageDemo: require('./images/icons/image-demo.png'),
  // patientDemo: require('./images/schedule/patient-demo.png'),
  // iconNoResult: require('./images/icon-no-result-search.png'),
  // iconAddNewBlue: require('./images/icons/add-new-blue.png'),
  // iconDownload: require('./images/icons/icon-download.png'),
  // iconShareFile: require('./images/icons/icon-share.png'),
  // iconPrint: require('./images/icons/icon-print.png'),
  // bookingSuccess: require('./images/booking-success.png'),

  subclinical: {
    xn1: require("./images/subclinical/xn1.png"),
  },

  statistical: {
    benhnhan: require("./images/statistical/benhnhan.png"),
    benhnhanduocgioithieu: require("./images/statistical/benhnhanduocgioithieu.png"),
    benhnhangioithieudi: require("./images/statistical/benhnhangioithieudi.png"),
    benhnhanhuykham: require("./images/statistical/benhnhanhuykham.png"),
    doanhsokham: require("./images/statistical/doanhsokham.png"),
    doanhsothuoc: require("./images/statistical/doanhsothuoc.png"),
    doanhsoxetnghiem: require("./images/statistical/doanhsoxetnghiem.png"),
    loinhuanthuoc: require("./images/statistical/loinhuanthuoc.png"),
  },

  LogoImedical: {
    logoQRcode: require("./images/logo-imedqrcode.png"),
    logoHome: require("./images/logo-imed.png"),
    logoLogin: require("./images/home/iMEDICAL-logo.png"),
    logoLoginBg: require("./images/home/imedical.jpg"),
  },
};
