import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function updateSigns<T>(id: number, values): NetworkPromiseResponse<T> {
    return new Promise((resolve, reject) => {
        network
            .post(`v1/sign-allergy/edit/${id}`, values)
            .then(res => {
                if (res.status >= 400) {
                    reject(res);
                }
                resolve(res as any);
            })
            .catch(err => {
                reject(err);
            });
    });
};

function createSigns<T>(values): NetworkPromiseResponse<T> {
    return new Promise((resolve, reject) => {
        network
            .post(`v1/sign-allergy/create`, values)
            .then(res => {
                if (res.status >= 400) {
                    reject(res);
                }
                resolve(res as any);
            })
            .catch(err => {
                reject(err);
            });
    });
};


const data = {
    updateSigns,
    createSigns
};

export default data;