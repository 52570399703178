import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function createPrescriptionForPatient<T>(prescription: any): NetworkPromiseResponse<T> {
    return new Promise((resolve, reject) => {
        network
            .post(`v1/prescription/create`, prescription, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res: any) => {
                if (res.status >= 400) {
                    reject(res);
                }
                resolve(res);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
}

function copyPrescription<T>(prescription: any): NetworkPromiseResponse<T> {
    return new Promise((resolve, reject) => {
        network
            .post(`v1/prescription/copy`, prescription, {   
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            .then((res: any) => {
                if (res.status >= 400) {
                    reject(res);
                }
                resolve(res);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
}
const data = {
    createPrescriptionForPatient,
    copyPrescription
};

export default data;