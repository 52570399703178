import user from "../user/actions";
// import first from '../first/actions';
// import token from '../token/actions';
// import schedule_patient from '../schedule_patient/actions';
import setting from '../setting/actions';

const allActions = {
  user,
  // first,
  // token,
  // schedule_patient,
  setting,
};

export default allActions;
