import React from "react";
import { Button } from "antd";

const OnDeleteButton = ({ onClick, disabled, title }) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    style={{
      marginRight: ".5rem",
    }}
    type="primary"
    danger
  >
    {title}
  </Button>
);

export default OnDeleteButton;
