import { useState } from "react";
import { Calendar, Select, Row, Col } from "antd";
import { Moment } from "moment";
import dayjs from "dayjs";
import "dayjs/locale/vi"; // Import the Vietnamese locale
import locale from "antd/lib/date-picker/locale/vi_VN";

// Nếu mà không có lịch booking thì set dataBoooking = [];
const CustomCalendar = ({ dateToMark, setCurrentTime }) => {
  const dateCellRender = (value) => {
    dayjs.locale("vi");
    const dateString = value.format("YYYY-MM-DD");
    // Kiểm tra xem ngày hiện tại có trong dataBooking không
    const bookingInfo = dateToMark.find(
      (booking) => booking.date === dateString
    );
    return (
      <div className="custom-date-cell">
        {bookingInfo && <div className="dot-marker" />}
      </div>
    );
  };
  const [months] = useState([]);
  const [start] = useState(0);
  const [end] = useState(12);
  const [monthOptions] = useState([]);

  return (
    <Calendar
      locale={locale}
      dateCellRender={dateCellRender}
      fullscreen={false}
      onChange={(value: Moment) => {
        setCurrentTime(value.format("YYYY-MM-DD"));
      }}
      headerRender={({ value, type, onChange, onTypeChange }) => {
        const current = value.clone();
        const localeData = value.localeData();

        for (let i = 0; i < 12; i++) {
          current.month(i);
          months.push(localeData.monthsShort(current));
        }

        for (let i = start; i < end; i++) {
          monthOptions.push(
            <Select.Option key={i} value={i} className="month-item">
              {months[i]}
            </Select.Option>
          );
        }

        const year = value.year();
        const month = value.month();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
          options.push(
            <Select.Option key={i} value={i} className="year-item">
              {i}
            </Select.Option>
          );
        }
        return (
          <Row>
            <Col>
              <Select
                size="small"
                dropdownMatchSelectWidth={false}
                className="my-year-select"
                value={year}
                onChange={(newYear) => {
                  const now = value.clone().year(newYear);
                  onChange(now);
                }}
              >
                {options}
              </Select>
            </Col>
            <Col>
              <Select
                size="small"
                dropdownMatchSelectWidth={false}
                value={month}
                onChange={(newMonth) => {
                  const now = value.clone().month(newMonth);
                  onChange(now);
                }}
              >
                {monthOptions}
              </Select>
            </Col>
          </Row>
        );
      }}
    />
  );
};

export default CustomCalendar;
