import { Col, Empty, List, Modal, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { AddButton, IconViewDetail } from "src/components";
import { api } from "src/services";
import {
  DocumentAndPicturesAdd,
  DocumentAndPicturesDetail,
  AlbumDetail,
} from "src/views";
const ListDocumentModal = ({ open, setIsOpenModalDocument, patient_id }) => {
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [isOpenModalCreateDocument, setIsOpenModalCreateDocument] =
    useState(false);
  const [isOpenModalViewDocument, setIsOpenModalViewDocument] = useState(false);
  const [isOpenModalViewAlbum, setIsOpenModalViewAlbum] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState([]);
  const [date, setDate] = useState("");
  const [random, setRandom] = useState(Math.random());
  const [albumPatient, setAlbumPatient] = useState([]);
  const [albumId, setAlbumId] = useState();
  const [images, setDocumentPictures] = useState([]);
  const [files, setFiles] = useState([]);

  const handleCancel = () => {
    setIsOpenModalDocument(false);
    setIsOpenModalCreateDocument(false);
    setIsOpenModalViewDocument(false);
  };

  const fetchData = async (page?: 1, pageSize?: 10) => {
    setLoading(true);
    const { data: rData, meta }: any = await api.getAllDocumentAndPictures(
      patient_id,
      pageSize,
      page
    );
    if (rData) {
      setTableParams({
        ...tableParams,
        pagination: {
          current: page,
          pageSize: meta.per_page,
          total: meta.total,
        },
      });
      setData(rData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchAlbumPatient = async () => {
    setLoading(true);
    const { data: rData }: any = await api.getAllAlbumsOfPatient(patient_id);
    if (rData) {
      setAlbumPatient(rData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchAlbumDetail = async () => {
    try {
      if (albumId) {
        setLoading(true);
        const { data: rData }: any = await api.getAlbumDetail(albumId);
        if (rData.length > 0) {
          const imagesArr = rData.map((element) => ({
            ...element.image,
            id: element.id,
            patientId: element.patient_id,
          }));
          const fileArr = rData
            .filter((element) => element.file !== "")
            .map((element) => ({
              ...element.file,
              id: element.id,
              patientId: element.patient_id,
            }));
          const patientId = parseInt(patient_id);
          const filteredImages = imagesArr.filter(
            (image) => image.patientId === patientId
          );
          const filteredFiles = fileArr.filter(
            (file) => file.patientId === patientId
          );

          const filteredData = filteredImages.filter(
            (element) => element.url && element.file_url
          );
          setFiles(filteredFiles);
          setDocumentPictures(filteredData);
        }else{
          setFiles([]);
          setDocumentPictures([]);
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickViewDetailAlbum = (albumItem) => {
    setIsOpenModalViewAlbum(true);
    setAlbumId(albumItem?.id);
    setRandom(Math.random());
  };

  useEffect(() => {
    fetchData();
    fetchAlbumPatient();
  }, [patient_id]);

  return (
    <div className="document-picture">
      <Modal
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
        centered
        className="modal-scroll"
      >
        <div className="btn-flex-start">
          <AddButton
            onClick={() => {
              setIsOpenModalCreateDocument(true);
            }}
            title="Thêm mới"
          />
        </div>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Danh sách tài liệu và hình ảnh" key="1">
            <List
              locale={{
                emptyText: <Empty description="Không có dữ liệu" />,
              }}
              style={{ marginTop: "1rem" }}
              loading={loading}
              bordered
              dataSource={data}
              renderItem={(item: any) => (
                <List.Item key={item?.id}>
                  <List.Item.Meta
                    title={
                      <Row className="format-row-ant">
                        <Col span={18}>
                          <div className="patient-booking-name">
                            <span>{item?.date_format}</span>
                          </div>
                        </Col>
                        <Col span={3}>
                          <div className="patient-booking-name">
                            <span>{item?.count_files} File</span>
                          </div>
                        </Col>
                        <Col span={1}>
                          <IconViewDetail
                            onClick={() => {
                              setIsOpenModalViewDocument(true);
                              setDate(item.date);
                              setRandom(Math.random());
                            }}
                          />
                        </Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Album" key="2">
            <List
              locale={{
                emptyText: <Empty description="Không có dữ liệu" />,
              }}
              style={{ marginTop: "1rem" }}
              loading={loading}
              bordered
              dataSource={albumPatient}
              renderItem={(item: any) => (
                <List.Item key={item?.id}>
                  <List.Item.Meta
                    title={
                      <Row className="format-row-ant">
                        <Col span={18}>
                          <div className="patient-booking-name">
                            <span>{item?.name}</span>
                          </div>
                        </Col>
                        <Col span={3}>
                          <div className="patient-booking-name">
                            <span>{item?.count_files} File</span>
                          </div>
                        </Col>
                        <Col span={1}>
                          <IconViewDetail
                            onClick={() => handleClickViewDetailAlbum(item)}
                          />
                        </Col>
                      </Row>
                    }
                  />
                </List.Item>
              )}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
      <Modal
        title="Thêm mới tài liệu hình ảnh"
        open={isOpenModalCreateDocument}
        centered={true}
        onCancel={() => {
          setIsOpenModalCreateDocument(false);
        }}
        footer={null}
        width={800}
      >
        <DocumentAndPicturesAdd
          id={patient_id}
          fetchData={fetchData}
          setIsOpenModalCreateDocument={setIsOpenModalCreateDocument}
          fetchAlbumPatient={fetchAlbumPatient}
          fetchAlbumDetail={fetchAlbumDetail}
        />
      </Modal>
      <Modal
        title="Xem tài liệu hình ảnh"
        open={isOpenModalViewDocument}
        centered={true}
        onCancel={() => {
          setIsOpenModalViewDocument(false);
        }}
        footer={null}
        width={800}
        className="modal-scroll"
      >
        <DocumentAndPicturesDetail
          random={random}
          Date={date}
          patient_id={patient_id}
        />
      </Modal>
      <Modal
        title="Xem album"
        open={isOpenModalViewAlbum}
        centered={true}
        onCancel={() => {
          setIsOpenModalViewAlbum(false);
        }}
        footer={null}
        width={800}
        className="modal-scroll"
      >
        <AlbumDetail
          fetchAlbumDetail={fetchAlbumDetail}
          albumId={albumId}
          images={images}
          files={files}
          loading={loading}
          random={random}
        />
      </Modal>
    </div>
  );
};

export default ListDocumentModal;
