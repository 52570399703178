export type ActionTypes = {
  type: "SAVE_USER" | "DELETE_USER";
  payload?: any;
};

const saveUser = (user: any) => {
  return {
    type: "SAVE_USER",
    payload: user,
  };
};

const deleteUser = () => {
  return {
    type: "DELETE_USER",
  };
};

const item = {
  saveUser,
  deleteUser,
};

export default item;
