import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import React from "react";

function ModalCreate(props) {
  const { open, cancel, form, handleSubmit, loading, title } = props;
  return (
    <div>
      <Modal title={title} open={open} footer={null} onCancel={cancel}>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item name="id">
            <span>
              <ExclamationCircleOutlined /> Bạn có chắc chắn thay đổi?
              <input type="number" hidden />
            </span>
          </Form.Item>
          <Form.Item
            style={{ textAlign: "right", marginBottom: "unset" }}
            name="submit"
          >
            <Button type="primary" loading={loading} htmlType="submit">
              Chắc Chắn
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ModalCreate;
