import { CloseCircleOutlined } from "@ant-design/icons";
import React from "react";

function IconCancel({ onClick, title }) {
  return (
    <span className="icon-container">
      <CloseCircleOutlined
        style={{ color: "red" }}
        onClick={onClick}
        title={title}
      />
    </span>
  );
}

export default IconCancel;
