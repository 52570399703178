import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/services";
import {
  ListClinicNote,
  ListOrderByTest,
  ListPrescription,
  ListSignsAndAllergy,
  ListSubclinical,
  PatientInformation,
} from "src/components";

export default function ScheduleDetail() {
  let { id }: any = useParams();
  const [patient, setPatient]: any = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPatient();
  }, []);

  const getPatient = async () => {
    try {
      setLoading(true);
      const response: any = await api.getMemberInfo(id);
      setPatient(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-table schedule-detail">
        <div className="schedule">
          <div className="">
            <div className="signs-and-allergy-container">
              <ListSignsAndAllergy patient={patient} getPatient={getPatient} />
            </div>
          </div>

          <div className="schedule__detail">
            <div className="patient-information">
              <Row gutter={8} className="patient-information-row">
                <Col span={24} key={1}>
                  {/* Thông tin bệnh nhân */}
                  <PatientInformation loading={loading} patient={patient} />
                </Col>
              </Row>
            </div>
            <div className="schedule__detail_group">
              <Row gutter={8}>
                <Col span={12} key={1}>
                  {/* ghi chú lâm sàn  */}
                  <ListClinicNote patient_id={patient?.id} />
                </Col>
                <Col span={12} key={2}>
                  {/* Cận lâm sàn  */}
                  <ListSubclinical patient_id={patient?.id} />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12} key={1}>
                  {/*toa thuốc  */}
                  <ListPrescription
                    patient_id={
                      patient?.member?.id ? patient?.member?.id : patient?.id
                    }
                    patient_name={
                      patient?.member?.name
                        ? patient?.member?.name
                        : patient?.name
                    }
                  />
                </Col>
                <Col span={12} key={2}>
                  {/* Phiếu chỉ định xét nghiệm  */}
                  <ListOrderByTest patient_id={patient?.id} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
    </div>
  );
}
