import React from "react";
import { FaPlus } from "react-icons/fa6";

function IconAdd({ title, onClick }) {
  return (
    <FaPlus
      onClick={onClick}
      title={title}
      style={{ color: "#007bff", cursor: "pointer" }}
    />
  );
}

export default IconAdd;
