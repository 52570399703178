import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Upload } from "antd";
import React from "react";

const ImportExcelMecineModal = ({
  isModalImportVisible,
  handleCancel,
  handleImport,
  handleRemove,
  handleBeforeUpload,
  fileList,
  excel,
}) => {
  return (
    <Modal
      title="Import file excel"
      open={isModalImportVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="ok" type="primary" onClick={handleImport}>
          Import 
        </Button>,
      ]}
    >
      <Form>
        <Form.Item>
          <Upload
            onRemove={handleRemove}
            beforeUpload={handleBeforeUpload}
            fileList={fileList}
            accept=".xlsx, csv"
          >
            <Button icon={<UploadOutlined />}> Chọn file excel</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <a href={excel} download="Medicine.xlsx">
            Click vào để tải mẫu import
          </a>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportExcelMecineModal;
