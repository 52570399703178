import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const AddButton = ({ onClick, title }) => (
  <Button onClick={onClick} icon={<PlusOutlined />} type="primary">
    {title}
  </Button>
);

export default AddButton;
