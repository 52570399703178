const userTypes = [
  {
    id: 1,
    name: "Bác sĩ",
  },
  {
    id: 2,
    name: "Bệnh nhân",
  },
  {
    id: 3,
    name: "Collector",
  },
  {
    id: 4,
    name: "Hub",
  },
];

const doctorType = 1;
const patientType = 2;
const collectorType = 3;
const hubType = 3;

export default {
  userTypes,
  doctorType,
  patientType,
  collectorType,
  hubType,
};
