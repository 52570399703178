import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getMyPatient<T>(
  member_type_id: number,
  page: number = 0,
  search = "",
  per_page: number = 0
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(
        `v1/friends?member_type_id=${member_type_id}&q=${search}&per_page=${per_page}&page=${page}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getDoctorFriendApi<T>(
  member_type_id: number,
  page: number,
  search = "",
  per_page: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(
        `v1/friends?member_type_id=${member_type_id}&q=${search}&per_page=${per_page}&page=${page}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteMyPatient<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/friensds/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteAllMyPatient<T>(ids: any): NetworkPromiseResponse<T> {
  const jsonIds = JSON.stringify(ids);
  return new Promise((resolve, reject) => {
    network
      .request({
        url: "v1/friends/all",
        method: "delete",
        data: jsonIds,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getMemberInfo<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/member/${id}`)
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getStatistical<T>(
  id: number,
  start_date: string,
  end_date: string
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/stats/${id}?start_date=${start_date}&end_date=${end_date}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getClinicalNotes<T>(
  id: number,
  per_page: number,
  page: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/clinical-notes/all/${id}?per_page=${per_page}&page=${page}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getClinicalNotesDetail<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/clinical-notes/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getAllPrescriptionOfPatient<T>(
  id: number,
  per_page: number,
  page: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/prescription/patient/${id}?per_page=${per_page}&page=${page}`)
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// function getAllPrescriptionOfPatient<T>(id: number): NetworkPromiseResponse<T> {
//   return new Promise((resolve, reject) => {
//     network
//       .get(`v1/prescription/${id}`)
//       .then((res) => {
//         if (res.status >= 400) {
//           reject(res);
//         }
//         resolve(res as any);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// }

function getTestAllDisease<T>(
  per_page: string | null,
  // page: number,
  q: string
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/type-disease/all?per_page=${per_page}&q=${q}`)
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function submitClinicalNotes<T>(formData: FormData): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/clinical-notes/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getMyPatient,
  getStatistical,
  deleteMyPatient,
  getMemberInfo,
  getClinicalNotes,
  getClinicalNotesDetail,
  getAllPrescriptionOfPatient,
  getTestAllDisease,
  getDoctorFriendApi,
  submitClinicalNotes,
  deleteAllMyPatient,
};

export default data;
