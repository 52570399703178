import { SettingTwoTone } from "@ant-design/icons";
import React from "react";

function IconSetting({ onClick }) {
  return (
    <span className="icon-container">
      {/* <SettingTwoTone
        style={{ color: "yellow" }}
        title="Cài đặt lịch khám"
        onClick={onClick}
      /> */}
      <span className="icon-text" onClick={onClick}>
        Tạo lịch dịch vụ
      </span>
    </span>
  );
}

export default IconSetting;
