import { EditOutlined } from "@ant-design/icons";
import React from "react";

function IconEdit({ onClick }) {
  return (
    <span className="icon-container">
      <EditOutlined
        style={{ color: "green" }}
        title={"Chỉnh sửa"}
        onClick={onClick}
      />
    </span>
  );
}

export default IconEdit;
