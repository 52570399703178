import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getAllBank<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get("v1/all-vietqr")
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getQRLink<T>(
  bankID: string,
  bankNumber: string,
  accountName?: string | null,
  price?: number | string | null,
  customerName?: string | null,
  customerPhone?: string | null
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(
        `v1/qr-links?bank_id=${bankID}&bank_number=${bankNumber}&account_name=${accountName}&price=${price}&customer_name=${customerName}&customer_phone=${customerPhone}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getAllBank,
  getQRLink,
};

export default data;
