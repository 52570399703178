function TreatmentregimenDetail(props) {
  const { content } = props;

  return (
    <div className="admin-table">
      <div className="flex-fill">
        <div>
          {content ? (
            <div>
              <p>{content}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default TreatmentregimenDetail;
