import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getNotifications<T>(
  cursor: number | null = null,
  perPage: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/notifications?cursor=${cursor}&per_page=${perPage}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function readNotification<T>(id: string): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/notifications/${id}/read`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getNotifications,
  readNotification,
};

export default data;
