import { DeleteOutlined } from "@ant-design/icons";
import React from "react";

function IconDelete({ onClick }) {
  return (
    <span className="icon-container">
      <DeleteOutlined
        disabled
        style={{ color: "red" }}
        onClick={onClick}
        title={"Xóa"}
      />
    </span>
  );
}

export default IconDelete;
