import { Col, Layout, Row } from "antd";
import { ReactElement } from "react";
// import LogoImed from "../assets/images/home/imedical.jpg";
import { images } from "src/assets";
const { Content } = Layout;

interface Props {
  children: ReactElement | ReactElement[];
}

function Authentication({ children }: Props) {
  return (
    <Layout className="authentication">
      <Content>
        <Row>
          <Col
            className="auth-item"
            span={17}
            xl={17}
            md={12}
            sm={0}
            style={{
              backgroundImage: `url(${images.LogoImedical.logoLoginBg})`,
            }}
          />
          <Col className="auth-item auth-form" sm={24} md={12} xl={7} span={7}>
            {children}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Authentication;
