import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getAllDocumentAndPictures<T>(
  patientid: number,
  per_page: number,
  page: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/all-file/${patientid}?per_page=${per_page}&page=${page}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getAllDocumentAndPicturesByDate<T>(
  date: string
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/all-file-by-date/${date}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function DocumentAndPicturesAdd<T>(
  formData: FormData
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/documents-pictures/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getAllAlbums<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/all-album`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getAllAlbumsOfPatient<T>(patient_id): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/patient-all-album/${patient_id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function createAlbum<T>(data): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/album/create`, data)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getAlbumDetail<T>(id : number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/album/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function fetchTimeline<T>(id: number, page: number, per_page: number = 20):  NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/timeline/${id}?page=${page}&per_page=${per_page}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}


const data = {
  getAllDocumentAndPictures,
  getAllDocumentAndPicturesByDate,
  DocumentAndPicturesAdd,
  getAllAlbums,
  createAlbum,
  getAllAlbumsOfPatient,
  getAlbumDetail,
  fetchTimeline
};

export default data;
