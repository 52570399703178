import { Card, Col, Form, Input, InputNumber, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { CardSave } from "src/components";

const CreateMedicineModal = ({
  isModalCreateVisible,
  handleCancel,
  handleSubmit,
  formCreate,
  loading,
}) => {
  return (
    <Modal
      title="Thêm mới loại thuốc"
      open={isModalCreateVisible}
      footer={null}
      onCancel={handleCancel}
      width={1400}
    >
      <Form onFinish={handleSubmit} form={formCreate}>
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <Card
              size="small"
              style={{
                marginBottom: 16,
                height: "unset",
                padding: "1rem 1rem 0 1rem",
              }}
              loading={loading}
            >
              <div className="wrap-medicine">
                <Form.Item
                  // label="Tên Thuốc"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Tên thuốc không được bỏ trống",
                      min: 2,
                      max: 191,
                    },
                  ]}
                >
                  <div className="form-center-20">
                    <label>Tên Thuốc :</label>
                    <Input placeholder="Tên thuôc" />
                  </div>
                </Form.Item>
                <Form.Item
                  // label="Đơn vị"
                  name="unit"
                  rules={[
                    {
                      required: true,
                      message: "Đơn vị không được để trống",
                    },
                  ]}
                >
                  <div className="form-center-20">
                    <label>Đơn vị :</label>
                    <Input type="text" placeholder="Đơn vị" />
                  </div>
                </Form.Item>
                <Form.Item
                  // label="Cách dùng"
                  name="drug_route"
                  rules={[
                    {
                      required: true,
                      message: "Cách dùng không được để trống",
                    },
                  ]}
                >
                  <div className="form-center-20">
                    <label>Cách dùng :</label>
                    <Input type="text" placeholder="Cách dùng" />
                  </div>
                </Form.Item>
                <Form.Item
                  // label="Số lượng tồn"
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "Số lượng không được để trống",
                    },
                  ]}
                >
                  <div className="form-center-20">
                    <label>Số lượng tồn :</label>
                    <Input type="number" placeholder="0" />
                  </div>
                </Form.Item>
                <Form.Item
                  // label="Giá nhập"
                  name="cost_of_goods_sold"
                  rules={[
                    {
                      required: true,
                      message: "Giá nhập không được để trống",
                    },
                  ]}
                >
                  <div className="form-center-20">
                    <label>Giá nhập :</label>
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      placeholder="Nhập giá tiền"
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  // label="Giá xuất"
                  name="cost_price"
                  rules={[
                    {
                      required: true,
                      message: "Giá xuất không được để trống",
                    },
                  ]}
                >
                  <div className="form-center-20">
                    <label>Giá xuất :</label>
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      placeholder="Nhập giá tiền"
                    />
                  </div>
                </Form.Item>
              </div>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Mô tả không được bỏ trống",
                  },
                ]}
              >
                
                <TextArea
                  showCount
                  maxLength={500}
                  rows={4}
                  placeholder="Mô tả thuốc"
                />
              </Form.Item>
            </Card>
          </Col>

          <Col span={6}>
            <CardSave loading={loading} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateMedicineModal;
