import { EyeOutlined } from "@ant-design/icons";
import React from "react";

function IconViewDetail({ onClick }) {
  return (
    <EyeOutlined
      title={"Xem chi tiết"}
      onClick={onClick}
      style={{ color: "#17a2b8" }}
    />
  );
}

export default IconViewDetail;
