import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

const BreadcrumbComponent = ({ items }) => (
  <Breadcrumb style={{ margin: "16px 0" }}>
    {items.map((item) => (
      <Breadcrumb.Item key={item.path}>
        <Link to={item.path}>{item.name}</Link>
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

export default BreadcrumbComponent;
