import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Skeleton,
  Table,
  Upload,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { CardSave } from "src/components";
import { api } from "src/services";
import { CategoriesType } from "src/types";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

function SubclinicalAdd(props) {
  const { id, fetchData, onSuccess } = props;
  const [SubclinicalsCategories, setSubclinicalsCategories] = useState<
    CategoriesType[]
  >([]);
  const formRef = useRef<any>(null);
  const [fileUpload, setFileUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [onlyTest, setOnlyTest] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState(false);
  const [SubclinicalId, setSubclinicalId] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleCheckUploadImage = () => {
    setFileUpload([]);
    setIsUploading(false);
  };

  useEffect(() => {
    handleCheckUploadImage();
    fetchSubclinicalsCategories();
  }, [props]);

  const fetchSubclinicalsCategories = async () => {
    try {
      setLoading(true);
      const { data: rData }: { data: CategoriesType[] } =
        await api.getSubclinicals();
      if (rData) {
        let patientArr = rData.map((el: any, index: number) => ({
          key: index,
          ...el,
        }));
        setSubclinicalsCategories(patientArr);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo);
  };

  const onBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      handleCheckUploadImage();
      fetchSubclinicalsCategories();
      setIsUploading(false);
      return false;
    } else {
      setIsUploading(true);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      // fetchSubclinicalsCategories();
      setIsUploading(false);
      return false;
    } else {
      setIsUploading(true);
    }

    const fileObject = {
      name: file.name,
      size: file.size,
      url: null,
      file: file,
    };
    setFileUpload((prevFiles) => prevFiles.concat(fileObject));

    getBase64(file, (url) => {
      fileObject.url = url;
    });
    return false;
  };

  const onRemovefile = (file) => {
    const updatedFiles = fileUpload.filter((item) => item.name !== file.name);
    if (updatedFiles.length === 0) {
      setIsUploading(false);
    }
    setFileUpload(updatedFiles);
  };

  const onRemoveImage = (file) => {
    const updatedFiles = fileUpload.filter((item) => item.name !== file.name);
    if (updatedFiles.length === 0) {
      setIsUploading(false);
    }
    setFileUpload(updatedFiles);
  };

  const onBeforeUploadFile = (file) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF files!");
      handleCheckUploadImage();
      fetchSubclinicalsCategories();
      setIsUploading(false);
      return false;
    } else {
      setIsUploading(true);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must be smaller than 2MB!");
      fetchSubclinicalsCategories();
      setIsUploading(false);
      return false;
    } else {
      setIsUploading(true);
    }

    const fileObject = {
      name: file.name,
      size: file.size,
      url: null,
      file: file,
    };
    setFileUpload((prevFiles) => prevFiles.concat(fileObject));

    getBase64(file, (url) => {
      fileObject.url = url;
    });

    return false;
  };

  const handleNameClick = (values) => {
    setSubclinicalId(values.id);
  };

  const handleSubmit = async () => {
    let formData = new FormData();
    formData.append("subclinical_cate_id", SubclinicalId);
    formData.append("patient_id", id);
    if (fileUpload.length > 0) {
      if (!onlyTest) {
        fileUpload.forEach((file, index) => {
          formData.append(`file[${index}]`, file.file);
        });
      } else {
        fileUpload.forEach((img, index) => {
          formData.append(`images[${index}]`, img.file);
        });
      }
      setLoading(true);
    } else {
      return message.error("Bạn chọn ít nhất 1 ảnh hoặc file");
    }

    try {
      const response: any = await api.SubclinicalsAdd(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      fetchSubclinicalsCategories();
      onSuccess();
      handleCheckUploadImage();
      fetchData();
    }
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "idSubclinicals",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "avatar",
      dataIndex: "icon_img",
      key: "icon_img",
      width: 50,
      render: (icon_img) => (
        <img src={icon_img} alt="icon_img" width="50" height="50" />
      ),
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
      width: 500,
      render: (name: any) => (
        <div>
          <p>Kết quả {name}</p>
        </div>
      ),
    },
    {
      title: "Thao tác",
      dataIndex: "operation",
      key: "operation",
      // width: 500,
      render: (text: string, record: any) => (
        <div>
          {!onlyTest ? (
            <Upload
              showUploadList={true}
              beforeUpload={onBeforeUploadFile}
              customRequest={() => {}}
              multiple={true}
              onRemove={onRemovefile}
              accept=".pdf"
            >
              <div>
                <div>
                  <Button
                    disabled={isUploading}
                    type="primary"
                    loading={loading}
                    onClick={() => handleNameClick(record)}
                    style={{ margin: "0.5rem" }}
                  >
                    Chọn file
                  </Button>
                </div>
              </div>
            </Upload>
          ) : (
            ""
          )}
          {onlyTest ? (
            <Upload
              showUploadList={true}
              beforeUpload={onBeforeUpload}
              // customRequest={() => {}}
              multiple={true}
              onRemove={onRemoveImage}
              accept=".jpg, .jpeg, .png"
            >
              <div>
                <div>
                  <Button
                    disabled={isUploading}
                    type="primary"
                    loading={loading}
                    onClick={() => handleNameClick(record)}
                    style={{ margin: "0.5rem" }}
                  >
                    Chọn ảnh
                  </Button>
                </div>
              </div>
            </Upload>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <div className="admin-table OrderTest">
      <div className="flex-fill">
        <Form
          ref={formRef}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          className="form"
        >
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Card size="small" loading={loading}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Button
                    className={onlyTest ? "" : "button-active"}
                    type="default"
                    size="large"
                    onClick={() => {
                      setOnlyTest(false);
                      handleCheckUploadImage();
                      setLoadingUpload(true);
                      setTimeout(() => {
                        setLoadingUpload(false);
                      }, 1000);
                    }}
                  >
                    Chọn file
                  </Button>
                  <Button
                    className={onlyTest ? "button-active" : ""}
                    type="default"
                    size="large"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      setOnlyTest(true);
                      handleCheckUploadImage();
                      setLoadingUpload(true);
                      setTimeout(() => {
                        setLoadingUpload(false);
                      }, 1000);
                    }}
                  >
                    Chọn ảnh
                  </Button>
                </Form.Item>
                <Form.Item name="examination_results">
                  <Skeleton loading={loadingUpload} active>
                    <Table
                      columns={columns}
                      dataSource={SubclinicalsCategories}
                      pagination={tableParams.pagination}
                      loading={loading}
                      onChange={handleTableChange}
                    />
                  </Skeleton>
                </Form.Item>
              </Card>
            </Col>
            <Col span={6}>
              <CardSave loading={loading} />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default SubclinicalAdd;
