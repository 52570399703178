export type ActionTypes = {
  type: 'SAVE_SETTING';
  payload?: Object;
};

const saveSetting = (settings: Object) => {
  return {
    type: 'SAVE_SETTING',
    payload: settings,
  };
};

export default {
  saveSetting,
};
