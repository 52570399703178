import React from "react";

const SpanMailTo = ({ mail }) => {
  return (
    <span>
      <a href={`mailto:${mail}`}>{mail}</a>
    </span>
  );
};

export default SpanMailTo;
