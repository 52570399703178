import {
  ExclamationCircleOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { Button, Form, Modal, Space, Table, UploadFile, message } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { excel } from "src/assets";
import {
  AddButton,
  BreadcrumbComponent,
  CreateMedicineModal,
  IconAddAmount,
  IconDelete,
  IconEdit,
  ModalDelete,
  OnDeleteButton,
  SearchInput,
  AddQuantityMedicineModal,
  ImportExcelMecineModal,
  UpdateMedicineModal,
} from "src/components";
import { api } from "src/services";

function Medicine() {
  const [medicines, setMedicines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setloadingExport] = useState(false);
  const [loadingImport, setloadingImport] = useState(false);
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [isModalImportVisible, setIsModalImportVisible] = useState(false);
  const [isModalAddQuantityVisible, setIsModalAddQuantityVisible] = useState(false);
  const [formCreate] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [formDelete] = Form.useForm();
  const [formAddQuantity] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const { confirm } = Modal;
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 2,
      pageSize: 20,
    },
  });

  const fetchAllMedicines = async (page?: 1, pageSize?: 20) => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getAllMedicines(
        page,
        pageSize,
        searchQuery
      );
      if (rData) {
        let medicineArr = rData.map((el: any) => ({
          key: el.id,
          ...el,
        }));
        setMedicines(medicineArr);
        setTableParams({
          ...tableParams,
          pagination: {
            current: page,
            pageSize: meta.per_page,
            total: meta.total,
          },
        });
      } else {
        setMedicines([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
  };

  useEffect(() => {
    setMedicines([]);
    fetchAllMedicines();
  }, [searchQuery]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchAllMedicines(pagination.current, pagination.pageSize);
  };

  function handleCancel() {
    setIsModalCreateVisible(false);
    setIsModalDeleteVisible(false);
    setIsModalUpdateVisible(false);
    setIsModalImportVisible(false);
    setIsModalAddQuantityVisible(false);
  }

  const handleSubmit = async (values) => {
    let formatCostOfGoodsSold = parseInt(values.cost_of_goods_sold);
    let formatCostPrice = parseInt(values.cost_price);
    if (formatCostOfGoodsSold > 0 || formatCostPrice > 0) {
      if (formatCostOfGoodsSold < formatCostPrice) {
        values.cost_price = formatCostPrice.toString();
        values.cost_of_goods_sold = formatCostOfGoodsSold.toString();
        try {
          const dataValue = {
            data: [values],
          };
          setLoading(true);
          const response: any = await api.createMedicine(dataValue);
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          console.log(error);
          message.error(error.message);
        } finally {
          fetchAllMedicines();
          setLoading(false);
          handleCancel();
        }
      } else {
        message.error("Số tiền bán phải lớn hơn số tiền nhập");
        return;
      }
    } else {
      message.error("Số tiền không thể là số âm");
      return;
    }
  };

  const handleUpdate = async (values) => {
    let formatCostOfGoodsSold = parseInt(values.cost_of_goods_sold);
    let formatCostPrice = parseInt(values.cost_price);
    if (formatCostOfGoodsSold > 0 || formatCostPrice > 0) {
      if (formatCostOfGoodsSold < formatCostPrice) {
        values.cost_price = formatCostPrice.toString();
        values.cost_of_goods_sold = formatCostOfGoodsSold.toString();
        try {
          setLoading(true);
          const response: any = await api.updateMedicine(values.id, values);
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          console.log(error);
          message.error(error.message);
        } finally {
          setLoading(false);
          handleCancel();
          fetchAllMedicines();
        }
      } else {
        message.error("Số tiền bán phải lớn hơn số tiền nhập");
        return;
      }
    } else {
      message.error("Số tiền không thể là số âm");
      return;
    }
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteMedicine(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      fetchAllMedicines();
      handleCancel();
      setLoading(false);
    }
  };

  const onConfirmDelete = () => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn các tên thuốc này không",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const response: any = await api.deleteAllMedicine(selectedRows);
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
          fetchAllMedicines();
          setSelectedRows([]);
        }
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };

  const handleAddQuantity = async (values) => {
    if (!isEmpty(values.quantity_new)) {
      let quantity_new = parseInt(values.quantity_new);
      values.quantity += quantity_new;
    } else {
      message.error("Số lượng ban đầu chưa nhập");
      return;
    }
    try {
      setLoading(true);
      const response: any = await api.updateMedicine(values.id, values);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      fetchAllMedicines();
      setLoading(false);
      handleCancel();
    }
  };

  const onExport = async () => {
    try {
      setloadingExport(true);
      const response: any = await api.exportMedicines();
      if (response.error) {
        message.error(response.message);
      } else {
        history.push(response.data.file_url);
        const absoluteUrl = `${response.data.file_url}`;
        window.location.href = absoluteUrl;
        window.open(absoluteUrl, "");
        window.history.back();
        message.success(response.message);
      }
    } catch (error) {
      message.error(error);
      console.log(error);
    } finally {
      setloadingExport(false);
    }
  };

  const handleRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleBeforeUpload = (file) => {
    setFileList([file]);
    return false;
  };

  const handleImport = async () => {
    try {
      setloadingImport(true);
      let formData: any = new FormData();
      formData.append(`filename`, fileList[0]);
      const response: any = await api.importMedicines(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error);
      console.log(error);
    } finally {
      setloadingImport(false);
      fetchAllMedicines();
      setIsModalImportVisible(false);
      setFileList([]);
      setloadingImport(false);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Tên thuốc",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Đơn vị",
      dataIndex: "unit",
      key: "unit",
      width: 100,
    },
    {
      title: "Cách dùng",
      dataIndex: "drug_route",
      key: "drug_route",
      width: 100,
    },
    {
      title: "Số lượng tồn",
      dataIndex: "quantity",
      key: "quantity",
      width: 100,
    },
    {
      title: "Giá nhập",
      dataIndex: "cost_of_goods_sold_formated",
      key: "cost_of_goods_sold_formated",
      width: 100,
    },
    {
      title: "Giá xuất",
      dataIndex: "cost_price_formated",
      key: "cost_price_formated",
      width: 100,
    },
    {
      title: "Hành động",
      width: 100,
      render: (text: string, record: any) => (
        <div>
          <IconAddAmount
            onClick={() => {
              setIsModalAddQuantityVisible(true);
              formAddQuantity.resetFields();
              formAddQuantity.setFieldsValue({
                id: record.id,
                quantity: record.quantity,
              });
            }}
          />
          <IconEdit
            onClick={() => {
              formUpdate.setFieldsValue({
                id: record.id,
                quantity: record.quantity,
                name: record.name,
                unit: record.unit,
                drug_route: record.drug_route,
                description: record.description,
                cost_price: record.cost_price > 0 ? record.cost_price : 0,
                cost_of_goods_sold:
                  record.cost_of_goods_sold > 0 ? record.cost_of_goods_sold : 0,
              });
              setIsModalUpdateVisible(true);
            }}
          />
          <IconDelete
            onClick={() => {
              setIsModalDeleteVisible(true);
              formDelete.setFieldsValue({
                id: record.id,
              });
            }}
          />
        </div>
      ),
    },
  ];

  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "#", name: "Cài đặt danh mục thuốc" },
  ];

  return (
    <div className="admin-table">
      <BreadcrumbComponent items={breadcrumbItems} />
      <div className="flex-fill">
        <div className="wp-action">
          <div className="wp-action-left">
            <OnDeleteButton
              onClick={onConfirmDelete}
              disabled={selectedRows.length === 0}
              title={"Xóa"}
            />
            <SearchInput
              onSearch={(value) => {
                setSearchQuery(value);
              }}
              title={"Tìm kiếm tên thuốc"}
              width={250}
            />
          </div>
          <div className="wp-action-right">
            <Button
              onClick={() => setIsModalImportVisible(true)}
              icon={<ImportOutlined />}
              type="primary"
              style={{ marginRight: "1rem" }}
              disabled={loading}
              loading={loadingImport}
            >
              Import
            </Button>
            <Button
              onClick={onExport}
              icon={<ExportOutlined />}
              type="primary"
              style={{ marginRight: "1rem" }}
              disabled={loading}
              loading={loadingExport}
            >
              Export
            </Button>

            <AddButton
              onClick={() => {
                setIsModalCreateVisible(true);
                formCreate.resetFields();
              }}
              title={"Thêm mới"}
            />
          </div>
        </div>
        <Table
          rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={medicines}
          loading={loading}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        />
      </div>
      <CreateMedicineModal
        isModalCreateVisible={isModalCreateVisible}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        formCreate={formCreate}
        loading={loading}
      />
      <UpdateMedicineModal
        isModalUpdateVisible={isModalUpdateVisible}
        handleCancel={handleCancel}
        handleUpdate={handleUpdate}
        formUpdate={formUpdate}
        loading={loading}
      />
      <ModalDelete
        open={isModalDeleteVisible}
        cancel={handleCancel}
        form={formDelete}
        handleSubmit={handleDelete}
        loading={loading}
        title={"Xóa thuốc"}
      />
      <AddQuantityMedicineModal
        isModalAddQuantityVisible={isModalAddQuantityVisible}
        handleCancel={handleCancel}
        handleAddQuantity={handleAddQuantity}
        formAddQuantity={formAddQuantity}
        loading={loading}
      />
      <ImportExcelMecineModal
        isModalImportVisible={isModalImportVisible}
        handleCancel={handleCancel}
        handleImport={handleImport}
        handleRemove={handleRemove}
        handleBeforeUpload={handleBeforeUpload}
        fileList={fileList}
        excel={excel.MedicineImport}
      />
    </div>
  );
}

export default Medicine;
