import { Button, Card, Col, Divider, Form, Input, InputRef, Row, Select, Skeleton, Space, Upload, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { api } from "src/services";
import { PlusOutlined } from "@ant-design/icons";
import { CardSave } from "src/components";

function DocumentAndPicturesAdd(props) {
  const { id, fetchData, setIsOpenModalCreateDocument, fetchAlbumPatient, fetchAlbumDetail } = props;
  const formRef = useRef<any>(null);
  const [fileUpload, setFileUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onlyTest, setOnlyTest] = useState<boolean>(false);
  const [checkFile, setCheckFile] = useState(null);
  let formData = new FormData();
  const [albums, setAlbums] = useState([]);
  const [nameAlbum, setNameAlbum] = useState('');
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    fetchAlbum();
  },[]);

  useEffect(() => {
    if (onlyTest) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [onlyTest, props]);

  // Album
  const addAlbum = async (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    try {
      const { data: rData }: any = await api.createAlbum({ 'name': nameAlbum, 'patient_id': id });
      if (rData) {
        setAlbums([...albums, rData]);
        setNameAlbum('');
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAlbum = async () => {
    try {
      setLoading(true);
      const { data: rData }: any = await api.getAllAlbumsOfPatient(id);
      if (rData) {
        setAlbums(rData);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return;
    }
    const fileObject = {
      name: file.name,
      size: file.size,
      url: null,
      file: file,
    };
    setFileUpload((prevFiles) => prevFiles.concat(fileObject));
    getBase64(file, (url) => {
      fileObject.url = url;
    });
    return false;
  };

  const onRemove = (file) => {
    const updatedFiles = fileUpload.filter((item) => item.name !== file.name);
    setFileUpload(updatedFiles);
  };

  const onBeforeUploadFile = (file) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF files!");
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return;
    }
    const fileObject = {
      name: file.name,
      size: file.size,
      url: null,
      file: file,
    };
    setFileUpload((prevFiles) => prevFiles.concat(fileObject));
    getBase64(file, (url) => {
      fileObject.url = url;
    });

    return false;
  };

  const handleSubmit = async (values: any) => {
    if (checkFile < 1) {
      message.error("Vui lòng chọn ít nhất 1 file");
      return;
    } else {
      formData.append("patient_id", id);
      try {
        if (fileUpload) {
          setLoading(true);
          if (!onlyTest) {
            fileUpload.forEach((file, index) => {
              formData.append(`file_upload[${index}]`, file.file);
            });
          } else {
            fileUpload.forEach((img, index) => {
              formData.append(`images[${index}]`, img.file);
            });
          }
          formData.append("album_id", values.album_id);
        }
        const response: any = await api.DocumentAndPicturesAdd(formData);
        if (response.error) {
          message.error(response.message);
        } else {
          message.success(response.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setFileUpload([]);
        setLoading(false);
        setIsOpenModalCreateDocument(false);
        fetchData();
        fetchAlbumPatient();
        fetchAlbumDetail();
      }
    }
  };

  return (
    <div className="admin-table OrderTest">
      <div className="flex-fill">
        <Skeleton loading={loading} active avatar>
          <Form
            ref={formRef}
            onFinish={handleSubmit}
            onFinishFailed={(errorInfo: any) => {
              console.log("Failed:", errorInfo);
            }}
            layout="vertical"
            className="form"
          >
            <Row gutter={[16, 16]}>
              <Col span={14}>
                <Card size="small" loading={loading}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Button
                      className={onlyTest ? "" : "button-active"}
                      type="default"
                      size="large"
                      onClick={() => {
                        setOnlyTest(false);
                        setFileUpload([]);
                        setCheckFile([]);
                      }}
                    >
                      Chọn file
                    </Button>
                    <Button
                      className={onlyTest ? "button-active" : ""}
                      type="default"
                      size="large"
                      style={{ marginLeft: "1rem" }}
                      onClick={() => {
                        setOnlyTest(true);
                        setFileUpload([]);
                        setCheckFile([]);
                      }}
                    >
                      Chọn ảnh
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <div>
                      {!onlyTest ? (
                        <Upload
                          listType="picture-card"
                          showUploadList={true}
                          beforeUpload={onBeforeUploadFile}
                          onChange={(e) => {
                            setCheckFile(e.fileList.length);
                          }}
                          onRemove={onRemove}
                          multiple={true}
                          accept=".pdf"
                        >
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Chọn file</div>
                          </div>
                        </Upload>
                      ) : (
                        ""
                      )}
                      {onlyTest ? (
                        <Upload
                          listType="picture-card"
                          showUploadList={true}
                          beforeUpload={onBeforeUpload}
                          onChange={(e) => {
                            setCheckFile(e.fileList.length);
                          }}
                          onRemove={onRemove}
                          multiple={true}
                          accept=".jpg, .jpeg, .png"
                        >
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Chọn ảnh</div>
                          </div>
                        </Upload>
                      ) : (
                        ""
                      )}
                    </div>
                  </Form.Item>
                </Card>
              </Col>
              <Col span={10}>
                <CardSave loading={loading} />
                <Card
                  size="small"
                  style={{
                    marginBottom: ".5rem",
                    height: 90,
                  }}
                  title="Chọn album"
                >
                  <Form.Item name="album_id">
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Chọn album"
                      notFoundContent="Không có dữ liệu"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />
                          <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                              placeholder="Nhập album mới"
                              ref={inputRef}
                              value={nameAlbum}
                              onChange={(event: any) => setNameAlbum(event.target.value)}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addAlbum}>
                              Thêm mới
                            </Button>
                          </Space>
                        </>
                      )}
                      options={albums.map((item) => ({ label: item?.name, value: item?.id }))}
                    />
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </div>
    </div>
  );
}

export default DocumentAndPicturesAdd;
