function AvatarName({ avatar, name, profileDoctor, viewDetail }) {
  return (
    <div className="item-avatar-profile">
      <div className="avatar-container">
        {avatar && (
          <img
            className="avatar"
            src={avatar}
            alt="Avatar"
            style={{ cursor: "pointer" }}
            onClick={profileDoctor}
          />
        )}
        <div>
          <span>
            <a style={{ fontWeight: "normal" }} onClick={viewDetail}>
              {name}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default AvatarName;
