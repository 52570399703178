import { EyeOutlined } from "@ant-design/icons";
import { Button, Card, Form, Image, Upload, message } from "antd";
import React, { useState } from "react";
import { api } from "src/services";

function AvatarInformation({ userLogin, fetchUserLogin }) {
  const [fileUpload, setFileUpload] = useState(null);
  const [, setLoadingFileUpload] = useState(false);
  const [, setLoading] = useState(false);
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }

    setLoadingFileUpload(true);
    setFileUpload(file);

    getBase64(file, (url) => {
      setLoadingFileUpload(false);
    });
    return false;
  };

  const handleChangeAvatar = async () => {
    try {
      let formData = new FormData();
      formData.append("avatar", fileUpload);
      setLoading(true);
      const res: any = await api.changeAvatar(formData);
      if (res && res.data) {
        setLoading(false);
        fetchUserLogin();
        message.success("Cập nhật avatar thành công");
        setFileUpload(null);
      }
    } catch (err) {
      message.error("Cập nhật avatar Thất bại");
      setLoading(false);
    }
  };
  return (
    <Card title="Hình đại điện" className="avatar-information" size="small">
      <Image
        width={85}
        height={85}
        src={userLogin?.avatar}
        style={{ borderRadius: "50%", objectFit: "cover" }}
        preview={{
          mask: <EyeOutlined />,
          maskClassName: "hide-preview-text",
        }}
      />
      <span style={{ marginLeft: "1rem" }}>
        <Upload
          onChange={handleChangeAvatar}
          beforeUpload={onBeforeUpload}
          customRequest={() => {}}
          showUploadList={null}
          accept=".jpg, .jpeg, .png"
        >
          <Button>Chọn hình ảnh</Button>
        </Upload>
      </span>
    </Card>
  );
}

export default AvatarInformation;
