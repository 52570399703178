import { Col, Row } from "antd";
import moment from "moment";

function PersonalInfo({ patientInfo }) {
  const dateFormat = "DD-MM-YYYY";

  return (
    <div className="order-info">
      <Row>
        <Col span={12}>
          <div className="info-group order-info-group ">
            <div className="info-group-title" style={{ fontWeight: "bold" }}>
              Họ và tên:
            </div>
            {patientInfo?.name ? (
              <div className="info-group-content">{patientInfo.name}</div>
            ) : null}
          </div>
        </Col>
        <Col span={12}>
          <div className="info-group order-info-group ">
            <div className="info-group-title" style={{ fontWeight: "bold" }}>
              Giới tính
            </div>
            {patientInfo?.gender ? (
              <div className="info-group-content">
                {patientInfo.gender == "male" ? "Nam" : "Nữ"}
              </div>
            ) : null}
          </div>
        </Col>
        <Col span={12}>
          <div className="info-group order-info-group ">
            <div className="info-group-title" style={{ fontWeight: "bold" }}>
              Số điện thoại:
            </div>
            {patientInfo?.phone ? (
              <div className="info-group-content">{patientInfo.phone}</div>
            ) : null}
          </div>
        </Col>
        <Col span={12}>
          <div className="info-group order-info-group ">
            <div className="info-group-title" style={{ fontWeight: "bold" }}>
              Email
            </div>
            {patientInfo?.email ? (
              <div className="info-group-content">{patientInfo.email}</div>
            ) : null}
          </div>
        </Col>
        <Col span={12}>
          <div className="info-group order-info-group ">
            <div className="info-group-title" style={{ fontWeight: "bold" }}>
              Ngày sinh:
            </div>
            {patientInfo?.dob ? (
              <div className="info-group-content">
                {moment(patientInfo.dob).format(dateFormat)}
              </div>
            ) : null}
          </div>
        </Col>
        <Col span={12}>
          <div className="info-group order-info-group ">
            <div className="info-group-title" style={{ fontWeight: "bold" }}>
              Địa chỉ:
            </div>
            {patientInfo?.address ? (
              <div className="info-group-content">{patientInfo.address}</div>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PersonalInfo;
