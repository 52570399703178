import moment from 'moment';
// import 'intl/locale-data/jsonp/en';
// import moment from 'moment';
// import 'moment/locale/vi';
// import {UserTypes} from 'types';
// moment.locale('vi');

const formatMoney = (money: number) => {
  let result = money.toString();
  let i = money.toString().length - 3;
  while (i > 0) {
    result = result.slice(0, i) + '.' + result.slice(i);
    i -= 3;
  }
  return result;
};

const dayNames: string[] = ['Hai', 'Ba', 'Tư', 'Năm', 'Sáu', 'Bảy', 'CN'];

const formatDate = (date: Date) => {
  let temp = moment(date).format('D [tháng] M, Y');
  const number: number = parseInt(moment(date).format('E').toString(), 10);
  if (number === 7) {
    return dayNames[number - 1] + ', ' + temp;
  }
  return 'T.' + dayNames[number - 1] + ', ' + temp;
};

const dateFormat = "DD-MM-YYYY";
const formatDay = (date: string) => {
  return moment(date).format(dateFormat);
}

const calculateAge = (dateString: string) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}


export default {
  formatMoney,
  formatDate,
  calculateAge,
  formatDay
};
