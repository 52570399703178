import React from "react";
import { Modal, Button, Table } from "antd";

const CreateScheduleModal = ({
  open,
  onCancel,
  loading,
  handleSubmit,
  columnSlot,
  data,
  tableCreateParams,
  handleTableCreateChange,
}) => {
  return (
    <Modal
      className="modal-croll"
      centered
      width={800}
      title="Tạo lịch khám"
      open={open}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Quay lại
        </Button>,
        <Button
          key="submit"
          type="primary"
          form="createForm"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit}
        >
          Thêm mới
        </Button>,
      ]}
    >
      <Table
        columns={columnSlot}
        dataSource={data}
        pagination={tableCreateParams.pagination}
        onChange={handleTableCreateChange}
      />
    </Modal>
  );
};

export default CreateScheduleModal;
