import { List, Tag, Skeleton, Card, Image } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/services";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

function DocumentAndPicturesDetail({ Date, patient_id, random }) {
  const [loading, setLoading] = useState(false);
  const [file, setDataFile] = useState([]);
  const [images, setDataImages] = useState([]);
  let { id }: any = useParams();

  useEffect(() => {
    fetchData();
  }, [Date, random]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: rData }: any = await api.getAllDocumentAndPicturesByDate(
        Date
      );
      if (rData) {
        if (id == null) {
          id = patient_id;
        }
        const imagesArr = rData.map((element) => ({
          ...element.image,
          id: element.id,
          patientId: element.patient_id,
        }));
        const fileArr = rData
          .filter((element) => element.file !== "")
          .map((element) => ({
            ...element.file,
            id: element.id,
            patientId: element.patient_id,
          }));
        const patientId = parseInt(id);
        const filteredImages = imagesArr.filter(
          (image) => image.patientId === patientId
        );
        const filteredFiles = fileArr.filter(
          (file) => file.patientId === patientId
        );

        const filteredData = filteredImages.filter(
          (element) => element.url && element.file_url
        );
        setDataImages(filteredData);
        setDataFile(filteredFiles);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <div className="admin-table OrderTest">
      <div className="flex-fill">
        {file.length > 0 && (
          <Card size="small" loading={loading} title="File">
            <Skeleton loading={loading}>
              <List
                size="small"
                dataSource={file}
                renderItem={(item: any) => (
                  <>
                    {item.url && (
                      <List.Item>
                        <Tag style={{ marginBottom: 10 }}>
                          <div className="tag-subclinical">
                            <a href={item.url} target="_blank">
                              {item.url}
                            </a>
                          </div>
                        </Tag>
                      </List.Item>
                    )}
                  </>
                )}
              />
            </Skeleton>
          </Card>
        )}
        {images.length > 0 && (
          <Card
            size="small"
            className="document-picture"
            style={{
              marginTop: 16,
              height: "unset",
            }}
            title="Ảnh:"
          >
            <Skeleton loading={loading}>
              <div>
                <ImageList
                  variant="quilted"
                  cols={Math.min(images.length, 5)}
                  gap={16}
                  rowHeight={
                    images.length === 4
                      ? 150
                      : images.length === 3
                      ? 200
                      : images.length === 2
                      ? 250
                      : images.length === 1
                      ? 500
                      : 121
                  }
                >
                  {images.map((item: any) => (
                    <div>
                      {item ? (
                        <ImageListItem
                          key={item.url}
                          cols={item.cols || 1}
                          rows={item.rows || 1}
                          className="list-images"
                        >
                          <img
                            {...srcset(item.url, 121, item.cols, item.rows)}
                            alt={item.url}
                            loading="lazy"
                            onClick={() => window.open(item.url)}
                            style={{ cursor: "pointer" }}
                          />
                        </ImageListItem>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </ImageList>
              </div>
            </Skeleton>
          </Card>
        )}
      </div>
    </div>
  );
}

export default DocumentAndPicturesDetail;
