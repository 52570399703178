import { Button, Form, Input, Modal } from "antd";
import React from "react";

const AddQuantityMedicineModal = ({
  isModalAddQuantityVisible,
  handleCancel,
  handleAddQuantity,
  formAddQuantity,
  loading,
}) => {
  return (
    <Modal
      title="Thêm số lượng"
      open={isModalAddQuantityVisible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form onFinish={handleAddQuantity} form={formAddQuantity}>
        <Form.Item
          label="Số lượng"
          name="quantity_new"
          rules={[
            {
              required: true,
              message: "Số lượng không được để trống",
            },
          ]}
        >
          <Input type="number" min={1} />
        </Form.Item>
        <Form.Item
          label="Số lượng hiện tại"
          name="quantity"
          style={{ display: "none" }}
        >
          <Input type="number" hidden />
        </Form.Item>
        <Form.Item style={{ textAlign: "right", marginBottom: "unset" }}>
          <Button type="primary" loading={loading} htmlType="submit">
            Thêm
          </Button>
        </Form.Item>
        <Form.Item name="id" style={{ display: "none" }}>
          <div>
            <input type="number" hidden />
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddQuantityMedicineModal;
