import { Card, Skeleton } from "antd";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const CardImageListNoDelete = ({ loading, ArrImage }) => {
  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
  return (
    <div>
      <Card
        size="small"
        style={{
          marginBottom: 16,
          height: "unset",
        }}
        title="Ảnh:"
      >
        <Skeleton loading={loading}>
          <div>
            <ImageList
              // sx={{ width: 1230, height: 450 }}
              variant="quilted"
              cols={Math.min(ArrImage.length, 5)}
              gap={16}
              rowHeight={
                ArrImage.length == 4
                  ? 150
                  : ArrImage.length == 3
                  ? 200
                  : ArrImage.length == 2
                  ? 250
                  : ArrImage.length == 1
                  ? 500
                  : 121
              }
            >
              {ArrImage.map((item: any) => (
                <div>
                  {item ? (
                    <ImageListItem
                      key={item.url}
                      cols={item.cols || 1}
                      rows={item.rows || 1}
                      className="list-images"
                    >
                      <img
                        {...srcset(item.url, 121, item.cols, item.rows)}
                        alt={item.url}
                        loading="lazy"
                        onClick={() => window.open(item.url)}
                        style={{ cursor: "pointer" }}
                      />
                    </ImageListItem>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </ImageList>
          </div>
        </Skeleton>
      </Card>
    </div>
  );
};

export default CardImageListNoDelete;
