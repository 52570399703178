import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { FaNoteSticky, FaPaperPlane } from "react-icons/fa6";
import {
  CardProfile,
  IconLocation,
  IconPhone,
  SearchInput,
  SpanPhone,
} from "src/components";
import { api } from "src/services";

const ListDoctorModal = ({
  patient_id,
  setIsOpenModal,
  setIsTextAreaVisible,
  isModalOpenDetail,
  isTextAreaVisible,
  formCreate,
}) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState([]);
  const [doctorArr, setDoctorArr] = useState(0);
  const [searchQuery, setSearchQuery]: any = useState("");
  const [isOpenModalProfile, setIsOpenModalProfile] = useState(false);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 3,
    },
  });

  function handleCanel() {
    setIsOpenModal(false);
    setIsOpenModalProfile(false);
  }

  useEffect(() => {
    fetchData();
  }, [searchQuery, patient_id]);

  const fetchData = async (page?: 1, pageSize?: 3) => {
    setLoading(true);
    const { data: rData, meta }: any = await api.getDoctorReffersById(
      patient_id,
      page,
      pageSize,
      searchQuery
    );
    if (rData) {
      setTableParams({
        ...tableParams,
        pagination: {
          current: page,
          pageSize: meta.per_page,
          total: meta.total,
        },
      });
      setData(rData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const formatFieldName = (fieldName, itemId) => {
    return fieldName.replace(`_${itemId}`, "");
  };
  const handleShowTextArea = (itemId) => {
    formCreate.resetFields();
    setSelectedItemId(itemId);
    setIsTextAreaVisible(true);
  };

  const handleSubmit = async (values) => {
    if (patient_id == null) {
      return message.error("Chưa có bệnh nhân");
    }
    if (values.note == null) {
      values.note = "Bạn khám giùm mình nhé!";
    }
    values.patient_id = patient_id;
    try {
      setLoading(true);
      const response: any = await api.createDoctorReffers(
        values.docter_id,
        values.patient_id,
        values.note
      );
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      handleCanel();
      fetchData();
    }
  };

  return (
    <Modal
      open={isModalOpenDetail}
      onCancel={handleCanel}
      footer={null}
      width={800}
      centered
      className="modal-scroll"
    >
      <SearchInput
        onSearch={(value) => {
          setSearchQuery(value);
        }}
        title={"Search"}
        width={250}
      />
      <Form style={{ width: "100%" }} form={formCreate} onFinish={handleSubmit}>
        <List
          loading={loading}
          size="small"
          bordered
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item key={item?.id}>
              <List.Item.Meta
                avatar={
                  (
                    <Avatar
                      className="hover-avatar item-avatar-profile"
                      onClick={() => {
                        setDoctorArr(item);
                        setIsOpenModalProfile(true);
                      }}
                      src={item?.avatar}
                    />
                  ) || <div></div>
                }
                title={
                  <Row className="format-row-ant">
                    <Col span={23}>
                      <div className="patient-booking-name ">
                        <span
                          className="item-text-profile"
                          onClick={() => {
                            setDoctorArr(item);
                            setIsOpenModalProfile(true);
                          }}
                        >
                          {item?.name}- {item?.specialist}
                        </span>
                      </div>
                    </Col>
                    <Col span={1}>
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          className="format-button"
                          onClick={() => {
                            formCreate.setFieldValue("docter_id", item.id);
                          }}
                        >
                          <FaPaperPlane style={{ color: "#1890ff" }} />
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                }
                description={
                  <Row>
                    <Col key={item.id} span={24}>
                      <Row>
                        <Col span={24}>
                          <IconPhone /> {<SpanPhone phone={item?.phone} />}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          {item?.address ? <IconLocation /> : ""}
                          <span className="patient-booking-detail-info-text">
                            {item?.address}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <FaNoteSticky />
                          <Button
                            className="format-button"
                            onClick={() => handleShowTextArea(item.id)}
                          >
                            Ghi chú
                          </Button>
                          {isTextAreaVisible && selectedItemId === item.id && (
                            <Form.Item
                              name={formatFieldName(`note_${item.id}`, item.id)}
                              rules={[
                                {
                                  required: true,
                                  message: "Ghi chú không được bỏ trống",
                                },
                              ]}
                            >
                              <Input.TextArea
                                showCount
                                maxLength={500}
                                style={{ marginBottom: ".5rem" }}
                                defaultValue={item?.note}
                              />
                            </Form.Item>
                          )}
                          <Form.Item name="docter_id" hidden>
                            <Input type="text" value={item?.id} hidden />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      </Form>

      <CardProfile
        doctorArr={doctorArr}
        isModalOpenProfile={isOpenModalProfile}
        setIsModalCancelProfile={() => setIsOpenModalProfile(false)}
      />
    </Modal>
  );
};

export default ListDoctorModal;
