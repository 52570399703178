import { ActionTypes } from "./actions";

type State = any | null;
const initState: State = null;

const reducer = (state: State = initState, action: ActionTypes) => {
  switch (action.type) {
    case "SAVE_USER":
      return action.payload;
    case "DELETE_USER":
      return null;
    default:
      return state;
  }
};

export default reducer;
