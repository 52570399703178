import { Button, Card, Form } from "antd";
import React from "react";

const CardSave = ({ loading }) => {
  return (
    <Card
      size="small"
      style={{
        marginTop: "1rem",
      }}
      bordered={false}
      // title="Xuất bản"
    >
      <Form.Item>
        <Button type="primary" loading={loading} htmlType="submit">
          Lưu
        </Button>
      </Form.Item>
    </Card>
  );
};

export default CardSave;
