

import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getBookings<T>(
  page: number,
  perPage: number,
  date: string,
  search : string ,
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/bookings/doctor?date=${date}&page=${page}&per_page=${perPage}&q=${search}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
function getBookingById<T>(
  id: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/bookings/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function updateBookingStatus<T>(
  id: number,
  status: 'done' | 'pending' | 'canceled',
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/bookings/${id}/status`, {status})
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);  
      });
  });
}

function updateBookingPayment<T>(
  id: number,
  payment_confirm: 'paid' | 'inpaid' | 'waiting_accept',
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/bookings/${id}/payment-confirm`, {payment_confirm})
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}


function getBookingBadge<T>(
  startDate: string,
  endDate: string,
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/bookings/badge?start_date=${startDate}&end_date=${endDate}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}



const data = {
  getBookings,
  updateBookingStatus,
  getBookingById,
  updateBookingPayment,
  getBookingBadge,
};

export default data;
