import React from "react";
import { Modal, Form, Button } from "antd";

const DeleteConfirmModal = ({
  open,
  onCancel,
  loading,
  handleSubmit,
  form,
}) => {
  return (
    <Modal
      title="Xác nhận xóa"
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item name="id">
          <div>
            <label style={{ marginBottom: "10px" }}>
              Bạn có chắc chắn muốn xóa không?
            </label>
            <input type="text" hidden />
          </div>
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Item style={{ textAlign: "right", marginBottom: "unset" }}>
            <Button type="primary" loading={loading} htmlType="submit">
              Xóa
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default DeleteConfirmModal;
