
import { Card, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { api } from 'src/services';
function BillingInformation() {
    const [ , setLoading] = useState(false);
    const [listBanks, setListBanks] = useState([]);

    useEffect(() => {
        fetchBank();
    }, []);


    const fetchBank = async () => {
        setLoading(true);
        const { data: rData }: any = await api.getAllBank();
        setLoading(false);
        if (rData && rData.data) {
            rData.data = rData.data.map((item) => ({
                value: item.code,
                label: `${item.shortName} - ${item.name}`,
            }));
            setListBanks(rData.data);
        }
    }
    return (
        <Card title="Thông tin thanh toán" bordered={true} className='billing-information'>
            <Form.Item
                label="Số tài khoản"
                name="bank_number"
                rules={[{ required: true, message: 'Số tài khoản không được để trống' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Tên chủ tài khoản"
                name="bank_username"
                rules={[{ required: true, message: 'Tên chủ tài khoản không được để trống' }]}
            >
                <Input />
            </Form.Item>
            {listBanks ? (
                <Form.Item
                    label="Ngân hàng"
                    name="bank_name"
                    rules={[{ required: true, message: 'Ngân hàng không được để trống' }]}
                >
                    <Select
                        showSearch
                        placeholder="Chọn ngân hàng"
                        optionFilterProp="children"
                        filterOption={(input :any, option: any) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA : any, optionB: any) : any =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={listBanks}
                    />

                </Form.Item>
            ) : ""}
        </Card>
    );
}

export default BillingInformation;