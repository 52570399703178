/* eslint-disable import/no-anonymous-default-export */
import user from "./user";
import patient from "./patient";
import orderPatientTest from "./order-patient-test";
import clinicSlot from "./clinic-slot";
import medicine from "./medicine";
import disease from "./disease";
import prescription from "./prescription";
import subclinicals from "./subclinicals";
import service from "./service";
import treatmentregimen from "./treatmentregimen";
import dictionary from "./dictionary";
import signature from "./signature";
import headletter from "./headletter";
import termspolicy from "./terms-policy";
import memberreferrers from "./member-referrers";
import vietqr from "./vietqr";
import imedicalTest from "./imedical-test";
import schedule from "./schedule";
import setting from "./setting";
import notification from "./notification";
import booking from "./booking";
import documentandpictures from "./document-and-pictures";
import sampleprescription from "./sample-prescription";

export default {
  ...user,
  ...patient,
  ...orderPatientTest,
  ...medicine,
  ...disease,
  ...clinicSlot,
  ...subclinicals,
  ...service,
  ...treatmentregimen,
  ...dictionary,
  ...prescription,
  ...signature,
  ...headletter,
  ...termspolicy,
  ...memberreferrers,
  ...vietqr,
  ...imedicalTest,
  ...schedule,
  ...setting,
  ...notification,
  ...booking,
  ...documentandpictures,
  ...sampleprescription,
};
