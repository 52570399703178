import React from "react";
import { Button, Form, Input, Modal } from "antd";

const DeleteScheduleModal = ({
  open,
  onCancel,
  onFinish,
  form,
  initialValues,
  isDeleting,
}) => {
  return (
    <Modal title="Xóa" open={open} footer={null} onCancel={onCancel}>
      <Form onFinish={onFinish} form={form} initialValues={initialValues}>
        <Form.Item name="id" hidden>
          <Input type="text" hidden />
        </Form.Item>
        <Form.Item name="date" hidden>
          <Input type="date" hidden />
        </Form.Item>
        <label> Bạn muốn xóa</label>
        <Form.Item
          style={{ textAlign: "right", marginBottom: "unset" }}
          name="repeat"
        >
          <Button
            type="primary"
            danger
            loading={isDeleting}
            style={{ marginRight: "0.5rem" }}
            onClick={() => {
              const updatedValues = {
                ...form.getFieldsValue(),
                repeat: "onlyday",
              };
              form.setFieldsValue(updatedValues);
              form.submit();
            }}
          >
            Chỉ một ngày
          </Button>
          <Button
            type="primary"
            danger
            style={{ marginRight: "0.5rem" }}
            loading={isDeleting}
            onClick={() => {
              const updatedValues = {
                ...form.getFieldsValue(),
                repeat: "daily",
              };
              form.setFieldsValue(updatedValues);
              form.submit();
            }}
          >
            Toàn bộ
          </Button>
          <Button type="primary" onClick={onCancel}>
            Trở về
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeleteScheduleModal;
