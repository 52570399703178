import React from "react";
import { FaUserDoctor } from "react-icons/fa6";

function IconCheckNow({ onClick }) {
  return (
    <span className="icon-container" onClick={onClick}>
      <FaUserDoctor title="Khám ngay" style={{ color: "#3dac33" }} />
    </span>
  );
}

export default IconCheckNow;
