import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getSetting<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/settings`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}


const data = {
    getSetting,
};

export default data;
