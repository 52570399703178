import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { List, Button, Tag, Modal, message, Card } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { api } from "src/services";
import { UserProperty } from "src/types";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import { FaXmark } from "react-icons/fa6";
const { confirm } = Modal;
function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function ClinicalNotesDetai(props) {
  const { number, subclinicalsId, patient_id } = props;
  const [loading, setLoading] = useState(false);
  const [file, setDataFile]: any = useState({});
  const [images, setDataImages] = useState([]);
  const [recordId, setRecordId] = useState(null);
  let { id }: any = useParams();
  const user = useSelector<Storage, UserProperty>((state) => state.user);

  useEffect(() => {
    setDataFile([]);
    setDataImages([]);
    fetchData();
  }, [number]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: rPatitent }: any = await api.getSubclinicalsDetail(
        subclinicalsId,
        id ? id : patient_id
      );
      if (rPatitent) {
        for (let i = 0; i < rPatitent.result.length; i++) {
          let item = rPatitent.result[i];
          if (item.doctor.id === user.id) {
            setRecordId(item.id);
            setDataFile(item.file);
            setDataImages(item.images);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (file_url) => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa không?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        setLoading(true);
        try {
          const response: any = await api.deleteSubclinicals(
            file_url,
            "file",
            recordId
          );
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          message.error(error.message);
          console.log(error);
        } finally {
          setLoading(false);
          fetchData();
        }
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };
  const deleteImage = (file_url) => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa không?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        setLoading(true);
        try {
          const response: any = await api.deleteSubclinicals(
            file_url,
            "image",
            recordId
          );
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          message.error("Xoá không thành công", error);
        } finally {
          setLoading(false);
          fetchData();
        }
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };

  return (
    <div className="admin-table OrderTest">
      <div className="flex-fill">
        <div>
          <Card
            title="Kết quả"
            size="small"
            loading={loading}
            style={{ marginBottom: 16 }}
          >
            {file.length > 0 ? (
              // <Skeleton loading={loading}>
              <List
                dataSource={file}
                renderItem={(item: any) => (
                  <List.Item>
                    <Tag>
                      <div className="tag-subclinical">
                        <a href={item.url} target="_blank">
                          {item.url}
                        </a>
                      </div>
                    </Tag>
                    <Button
                      type="primary"
                      danger
                      loading={loading}
                      onClick={() => handleClose(item.file_url)}
                    >
                      <DeleteOutlined />
                      Xoá
                    </Button>
                  </List.Item>
                )}
              />
            ) : (
              // </Skeleton>
              "Không tìm thấy thông tin"
            )}
          </Card>

          <div>
            <Card
              size="small"
              style={{
                marginBottom: 16,
                height: "unset",
              }}
              title="Ảnh:"
            >
              <div>
                {images.length > 0 ? (
                  <ImageList
                    variant="quilted"
                    cols={Math.min(images.length, 5)}
                    gap={16}
                    rowHeight={
                      images.length === 4
                        ? 150
                        : images.length === 3
                        ? 200
                        : images.length === 2
                        ? 250
                        : images.length === 1
                        ? 500
                        : 121
                    }
                  >
                    {images.map((item: any) => (
                      <div>
                        {item.url ? (
                          <ImageListItem
                            key={item.url}
                            cols={item.cols || 1}
                            rows={item.rows || 1}
                            className="list-images"
                          >
                            <img
                              {...srcset(item.url, 121, item.cols, item.rows)}
                              alt={item.url}
                              loading="lazy"
                              onClick={() => window.open(item.url)}
                              style={{ cursor: "pointer" }}
                            />
                            <ImageListItemBar
                              sx={{
                                background:
                                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                  "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                              }}
                              title={item.title}
                              position="top"
                              actionIcon={
                                <IconButton
                                  sx={{ color: "white" }}
                                  aria-label={`star ${item.title}`}
                                >
                                  <FaXmark
                                    onClick={() => deleteImage(item.file_url)}
                                  />
                                </IconButton>
                              }
                              actionPosition="right"
                            />
                          </ImageListItem>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </ImageList>
                ) : (
                  "Không tìm thấy thông tin"
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClinicalNotesDetai;
