import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  CustomCalendar,
  ListClinicNote,
  ListPatientBooking,
  ListPrescription,
  ListSubclinical,
  PatientInformation,
  ListSignsAndAllergy,
  ListOrderByTest,
  SearchInput,
} from "src/components";
import { Col, Empty, Row } from "antd";
import { api } from "src/services";

interface DataType {
  phone: string;
  address: string;
  payment_method: {
    label: string;
    value: string;
  };
  order_number: number;
  name: string;
  member: {
    avatar: string;
    id: number;
  };
  dob: string;
  slot_start_time: string;
  gender: string;
}

function Schedule() {
  const [bookingBadge, setBookingBadge]: any = useState([]);
  const [currentTime, setCurrentTime] = useState(moment().format("YYYY-MM-DD"));
  const [listPatientBooking, setListPatientBooking] = useState<DataType[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedPatient, setSelectedPatient]: any = useState({});
  const [current, setCurent]: any = useState(1);
  const [pageSize, setPageSize]: any = useState(10);
  const [total, setTotal]: any = useState(10);

  useEffect(() => {
    fetchListPatitent(current, pageSize);
    fetchBookingBadge();
  }, [currentTime, searchQuery, current, pageSize]);

  // Hàm lấy tất cả bệnh nhân đặt khám trong ngày
  const fetchListPatitent = async (current = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getBookings(
        current,
        pageSize,
        currentTime,
        searchQuery
      );
      if (rData.length > 0) {
        setSelectedPatient(rData[0].member ? rData[0].member : []);
        setListPatientBooking(rData);
        setTotal(meta.total);
      } else {
        setSelectedPatient("");
        setListPatientBooking([]);
        setTotal(0);
      }
    } catch (error) {
      setListPatientBooking([]);
      setTotal(0);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Hàm lấy lấy tất cả những ngày có bệnh nhân đặt lịch
  const fetchBookingBadge = async () => {
    try {
      setLoading(true);
      const startDate = moment(currentTime)
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = moment(currentTime).endOf("month").format("YYYY-MM-DD");
      const { data: rData }: any = await api.getBookingBadge(
        startDate,
        endDate
      );
      if (rData) {
        setBookingBadge(rData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // Khi click vào bệnh nhân bên danh sách bệnh nhân thì bệnh nhân được click set vào setSelectedPatient
  const handleClickPatient = (patient) => {
    setSelectedPatient(patient);
  };
  const getPatient = async (id) => {
    try {
      setLoading(true);
      const { data: rData }: any = await api.getMemberInfo(id);
      if (rData) {
        setSelectedPatient(rData);
      } else {
        setSelectedPatient("");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="schedule">
      <div className="schedule__calendar">
        <div className="calendar-container">
          <div className="calendar-custom">
            <CustomCalendar
              dateToMark={bookingBadge}
              setCurrentTime={setCurrentTime}
            />
            <SearchInput
              onSearch={(value) => {
                setSearchQuery(value);
              }}
              title={"Tìm kiếm bệnh nhân"}
              width={"100%"}
            />
          </div>
          {/* Sinh dị và hiệu ứng */}
          <ListSignsAndAllergy
            patient={selectedPatient}
            getPatient={getPatient}
          />
        </div>
        <div className="calendar-patient bg-white">
          {/* Danh sách bệnh nhân  */}
          <ListPatientBooking
            loading={loading}
            handleClickPatient={handleClickPatient}
            listPatientBooking={listPatientBooking}
            fetchListPatitent={fetchListPatitent}
            setLoading={setLoading}
            activePatient={selectedPatient}
            current={current}
            setCurent={setCurent}
            pageSize={pageSize}
            setPageSize={setPageSize}
            total={total}
          />
        </div>
      </div>
      <div className="schedule__detail">
        <div className="patient-information">
          <Row gutter={8} className="patient-information-row">
            <Col span={24} key={1}>
              {/* Thông tin bệnh nhân */}
              <PatientInformation loading={loading} patient={selectedPatient} />
            </Col>
          </Row>
        </div>
        <div className="schedule__detail_group">
          <Row gutter={8} className="schedule__detail_group_row">
            <Col span={12} key={1}>
              {/* Ghi chú lâm sàng */}
              <ListClinicNote patient_id={selectedPatient?.id} />
            </Col>
            <Col span={12} key={2}>
              {/* Cận lâm sàn  */}
              <ListSubclinical patient_id={selectedPatient?.id} />
            </Col>
          </Row>
          <Row gutter={8} className="schedule__detail_group_row">
            <Col span={12} key={1}>
              {/*toa thuốc  */}
              <ListPrescription
                patient_id={
                  selectedPatient?.member?.id
                    ? selectedPatient?.member?.id
                    : selectedPatient?.id
                }
                patient_name={
                  selectedPatient?.member?.name
                    ? selectedPatient?.member?.name
                    : selectedPatient?.name
                }
              />
            </Col>
            <Col span={12} key={2}>
              {/* phiếu chỉ định xét nghiệm */}
              <ListOrderByTest patient_id={selectedPatient?.id} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Schedule;
