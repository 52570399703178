import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getAllDisease<T>(search = ''): NetworkPromiseResponse<T> {
    return new Promise((resolve, reject) => {
        network
            .get(`v1/type-disease/all?q=${search}`)
            .then((res: any) => {
                if (res.status >= 400) {
                    reject(res);
                }
                resolve(res);
            })
            .catch((err: any) => {
                reject(err);
            });
    });
}

const data = {
    getAllDisease
};

export default data;