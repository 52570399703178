import { useRef } from "react";
import {
  Button,
  Form,
  Input,
} from "antd";

function CopySamplePrescription({
    handleCopySendReq,
    handleCancelModal
}) {
  const formRef = useRef<any>(null);
  const [form] = Form.useForm();
  const handleFinish = (filedValues: any) => {
    handleCopySendReq(form.getFieldValue('name'));
    handleCancelModal();
  };
  return (
    <div className="admin-table orderTest_form">
      <Form
        onFinish={handleFinish}
        form={form}
        ref={formRef}
        className="form-sample-order-test"
      >
        <Form.Item
          className="item_sample_ordertest"
          label="Tên toa thuốc mẫu"
          name="name"
          rules={[
            {
              required: true,
              message: "Tên toa thuốc mẫu không được để trống",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CopySamplePrescription;
