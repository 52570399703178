import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getSamplePrescription<T>(
  page: number = 1,
  per_page: number = 10,
  search: string = ""
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(
        `v1/sample-prescription/doctor/all?q=${search}&per_page=${per_page}&page=${page}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function createSamplePrescription<T>(data): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/sample-prescription/create`, data)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function editSamplePrescription<T>(id, data): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/sample-prescription/edit/` + id, data)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getSamplePrescriptionDetail<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/sample-prescription/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteSamplePrescription<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/sample-prescription/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteListSamplePrescription<T>(ids: any): NetworkPromiseResponse<T> {
  const jsonIds = JSON.stringify(ids);
  return new Promise((resolve, reject) => {
    network
      .request({
        url: "v1/sample-prescription/list",
        method: "delete",
        data: jsonIds,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getSamplePrescription,
  createSamplePrescription,
  getSamplePrescriptionDetail,
  deleteSamplePrescription,
  deleteListSamplePrescription,
  editSamplePrescription
};

export default data;
