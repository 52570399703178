import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function createOrderTest<T>(formData: any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/order-patient-test/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getTestPriority<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/packet-test/all`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getTestPlace<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/sampling-package/all`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getTestCategories<T>(
  per_page: number,
  q: string
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/category-test/all?per_page=${per_page}&q=${q}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getAllTestCategories<T>(search: string): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/category-test/all?q=${search}&per_page=10000`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getOrderPatient<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/order-patient-test/${id}`)
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function deleteOrderPatient<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/order-patient-test/${id}`)
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function submitTest<T>(value): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/order-patient-test/create`, value)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
function listOfProvinces<T>(
  province_id: any,
  district_id: any
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/provinces?province_id=${province_id}&district_id=${district_id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function createSampleOrderTest<T>(formData): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/sample-order-test/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
function getSampleOrderTestByDoctor<T>(
  page: number = 1,
  per_page: number = 10,
  search: string = ""
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(
        `v1/all-sample-order-doctor?q=${search}&per_page=${per_page}&page=${page}`
      )
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getSampleTestById<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/sample-order-test/${id}`)
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function deleteSampleOrderTest<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/sample-order-test/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteListSampleOrderTest<T>(ids: any): NetworkPromiseResponse<T> {
  const jsonIds = JSON.stringify(ids);
  return new Promise((resolve, reject) => {
    network
      .request({
        url: "v1/delete-sample-order-test",
        method: "delete",
        data: jsonIds,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
function updatedSampleOrderTest<T>(
  id: number,
  formData: any
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/sample-order-test/edit/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
const data = {
  createOrderTest,
  getTestPriority,
  getTestPlace,
  getTestCategories,
  submitTest,
  getOrderPatient,
  getAllTestCategories,
  deleteOrderPatient,
  listOfProvinces,
  createSampleOrderTest,
  getSampleOrderTestByDoctor,
  getSampleTestById,
  deleteSampleOrderTest,
  deleteListSampleOrderTest,
  updatedSampleOrderTest,
};

export default data;
