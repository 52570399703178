import axios from "axios";
import queryString from "query-string";
import _ from "lodash";
import { message } from "antd";

const network = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});
network.interceptors.request.use(async (config: any) => {
  config.headers["Authorization"] =
    "Bearer " + localStorage.getItem("access_token");
  return {
    ...config,
  };
});
network.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
    } else {
      if (!!error.response) {
        const status = _.get(error, "response.status", 400);
        switch (status) {
          case 401:
            if (!localStorage.getItem("access_token")) break;
            localStorage.setItem("access_token", "");
            break;
          case 422:
            message.error(error?.response?.data?.message);
            break;
          case 404:
            message.error(error?.response?.data?.message);
            break;
          case 400:
            message.error(error?.response?.data?.message);
            break;
        }
      }
    }

    throw error;
  }
);
export default network;
