import { Collapse, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { ServicePane, ServiceResultItem } from "src/components";
import { api } from "src/services";

function ServiceModalDetail(props) {
  const { open, onCancel, serviceResultDetail, patient_id, serviceKey } = props;
  const [service, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subclinicals, setSubclinicals] = useState([]);
  const [servicePatient, setServicePatient] = useState([]);

  const { Panel } = Collapse;

  const fechSubclinicalsDetail = async (serverId, patient_id) => {
    try {
      setLoading(true);
      const { data: Subclinicals }: any = await api.getSubclinicalsDetail(
        serverId,
        patient_id
      );
      if (Subclinicals) {
        setSubclinicals(Subclinicals);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const fechServiceResult = async (patient_id) => {
    try {
      setLoading(true);
      const { data: servicePatient }: any = await api.getServiceResultPatient(
        patient_id
      );
      console.log(servicePatient, patient_id);

      if (servicePatient) {
        setServicePatient(servicePatient);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const onChange = (key) => {
    setServices(key);
    fechSubclinicalsDetail(key, patient_id);
  };
  useEffect(() => {
    fechServiceResult(patient_id);
  }, [patient_id]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Chi tiết kết quả dịch vụ"
      footer={null}
      width={900}
    >
      <Collapse accordion onChange={onChange} defaultActiveKey={[serviceKey]}>
        {serviceResultDetail.map((service) => (
          <Panel header={service.name} key={service.id}>
            <Spin spinning={loading}>
              {service.id == null ? (
                <ServicePane servicePatient={servicePatient} />
              ) : (
                <ServiceResultItem
                  serviceResultDetail={serviceResultDetail}
                  subclinicals={subclinicals}
                />
              )}
            </Spin>
          </Panel>
        ))}
      </Collapse>
    </Modal>
  );
}

export default ServiceModalDetail;
