import { Input, Table } from "antd";
import { useEffect, useState } from "react";
import {
  AvatarName,
  BreadcrumbComponent,
  IconViewDetail,
  SpanPhone,
} from "src/components";
import { utils } from "src/utils";
import _ from "lodash";
import { api } from "src/services";
import { useHistory } from "react-router-dom";
import { ServiceModalDetail } from "src/views";

function ServiceResult(props) {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [serviceResult, setServiceResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [serviceResultDetail, setServiceResultDetail] = useState([]);
  const [serviceKey, setServiceKey] = useState([]);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [servicePatient, setServicePatient] = useState([]);

  const [number, setNumber] = useState(0);
  const limiter = 1;
  const [patient_id, setPatient_id] = useState("");

  useEffect(() => {
    fetchServiceResult();
  }, [searchValue]);

  const fetchServiceResult = async (page = 1, pageSize = 15) => {
    try {
      setLoading(true);
      const { data: serviceData, meta }: any = await api.getServiceResult(
        searchValue,
        pageSize,
        page
      );
      if (serviceData) {
        setTableParams({
          ...tableParams,
          pagination: {
            current: page,
            pageSize: meta.per_page,
            total: meta.total,
          },
        });
        setServiceResult(serviceData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchServiceResultDetail = async (patient_id) => {
    try {
      setLoading(true);
      const { data: rData }: any = await api.getAllSubclinicals(patient_id);
      if (rData) {
        setServiceResultDetail(rData);
        setIsModalOpenDetail(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fechServiceResultPatient = async (patient_id) => {
    try {
      setLoading(true);
      const { data: ServicePatient }: any = await api.getServiceResultPatient(
        patient_id
      );

      if (ServicePatient) {
        setServicePatient(ServicePatient);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetail = (patient_id) => {
    fetchServiceResultDetail(patient_id);
    setPatient_id(patient_id);
    setServiceResultDetail(serviceResultDetail);
    setServiceKey(null);
    fechServiceResultPatient(patient_id);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchServiceResult(pagination.current, pagination.pageSize);
  };

  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "/service-result", name: "Kết quả dịch vụ" },
  ];

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Bệnh nhân",
      dataIndex: "avatar_url",
      key: "avatar_url",
      render: (avatar_url, record) => (
        <div>
          <AvatarName
            avatar={avatar_url}
            name={record?.name}
            profileDoctor={() => {
              history.push(`/my-patient/schedule-detail/${record.id}`);
            }}
            viewDetail={() => handleViewDetail(record.id)}
          />
        </div>
      ),
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      width: 150,
      render: (text: string, record: any) => <SpanPhone phone={text} />,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Tuổi",
      dataIndex: "dob",
      key: "dob",
      width: 100,
      render: (text: string, record: any) => utils.calculateAge(text),
    },
    {
      title: "Ngày sinh",
      dataIndex: "dob",
      key: "dob",
      width: 150,
      render: (text: string, record: any) => utils.formatDay(text),
    },
    {
      title: "Hành động ",
      dataIndex: "action",
      key: "action",
      width: 130,
      render: (text: string, record: any) => (
        <IconViewDetail onClick={() => handleViewDetail(record.id)} />
      ),
    },
  ];

  return (
    <div className="admin-table my-patient-result">
      <BreadcrumbComponent items={breadcrumbItems} />
      <div className="flex-fill">
        <div className="wp-action" style={{ marginBottom: "1rem" }}>
          <div className="wp-action-left">
            <Input
              style={{
                width: 250,
                marginRight: ".5rem",
              }}
              allowClear
              onChange={_.debounce(function (e: any) {
                setSearchValue(e.target.value);
              }, 300)}
              placeholder="Tìm kiếm"
            />
          </div>
          <div className="wp-action-right"></div>
        </div>
        <Table
          columns={columns}
          dataSource={serviceResult}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
      <ServiceModalDetail
        open={isModalOpenDetail}
        key={patient_id}
        onCancel={() => setIsModalOpenDetail(false)}
        serviceResultDetail={serviceResultDetail}
        patient_id={patient_id}
        serviceKey={serviceKey}
      />
    </div>
  );
}

export default ServiceResult;
