import { Modal } from "antd";
import React from "react";

function DictionaryDetail(props) {
  const { title, open, handleCancel, data } = props;
  return (
    <div>
      <Modal
        title={title}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
      >
        <p>{data}</p>
      </Modal>
    </div>
  );
}

export default DictionaryDetail;
