import React from "react";
import { Modal, Form, Input, Button, InputNumber } from "antd";

const CreateServiceModal = ({
  open,
  onCancel,
  loading,
  handleSubmit,
  form,
}) => {
  return (
    <Modal
      title="Thêm mới dịch vụ"
      open={open}
      footer={null}
      onCancel={onCancel}
    >
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item
          // label="Tên dịch vụ"
          name="name"
          rules={[
            {
              required: true,
              message: "Tên dịch vụ không được bỏ trống",
            },
          ]}
        >
          <div className="form-center">
            <label> Tên dịch vụ : </label>
            <Input type="text" placeholder="Nhập vào tên dịch vụ cần thêm" />
          </div>
        </Form.Item>
        <Form.Item
          // label="Giá"
          name="price"
          rules={[
            {
              required: true,
              message: "Giá không được để trống",
            },
          ]}
        >
          <div className="form-center">
            <label> Giá : </label>
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              placeholder="Nhập giá tiền"
            />
          </div>
        </Form.Item>
        <Form.Item style={{ textAlign: "right", marginBottom: "unset" }}>
          <Button type="primary" loading={loading} htmlType="submit">
            Thêm
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateServiceModal;
