import React from "react";
import { FaRegCalendar } from "react-icons/fa6";

function IconDateCreate({ created_at }) {
  return (
    <div className="size-xs align-center ">
      <FaRegCalendar style={{ color: "#6c757d" }} />
      <span style={{ paddingLeft: "2px" }}>{created_at}</span>
    </div>
  );
}

export default IconDateCreate;
