import { CheckCircleOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Empty,
  Form,
  List,
  Pagination,
  Row,
  Space,
  Tag,
  message,
} from "antd";
import { useState } from "react";
import {
  DeleteConfirmationModal,
  IconCancel,
  IconCheck,
  IconLocation,
  IconPhone,
  PaymentConfirmationModal,
  SpanPhone,
} from "src/components";
import { api } from "src/services";
import { utils } from "src/utils";

function ListPatientBooking({
  listPatientBooking,
  handleClickPatient,
  loading,
  fetchListPatitent,
  setLoading,
  activePatient,
  current,
  setCurent,
  pageSize,
  setPageSize,
  total,
}) {
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);

  const [formDelete] = Form.useForm();
  const [formUpdate] = Form.useForm();

  const handleCancel = () => {
    setDeleteModal(false);
    setUpdateModal(false);
  };

  const handleUpdate = async (values) => {
    const payment_confirm = "paid";
    try {
      setLoading(true);
      const response: any = await api.updateBookingPayment(
        values.id,
        payment_confirm
      );
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.updateBookingStatus(
        values.id,
        "canceled"
      );
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  return (
    <>
      <List
        locale={{
          emptyText: (
            <Empty description="Không có bệnh nhân đặt lịch khám trong ngày hôm nay" />
          ),
        }}
        className="calendar-patient-list"
        loading={loading}
        header={<div className="font-bold">Danh sách bệnh nhân</div>}
        size="small"
        bordered
        footer={
          total > 10 ? (
            <Pagination
              style={{ float: "right" }}
              size="small"
              defaultCurrent={1}
              total={total}
              current={current}
              defaultPageSize={10}
              pageSize={pageSize}
              onChange={(current, pageSize) => {
                setCurent(current);
                setPageSize(pageSize);
              }}
            />
          ) : null
        }
        dataSource={listPatientBooking}
        renderItem={(item: any) => (
          <List.Item
            onClick={() => {
              if (item.status.value !== "canceled") {
                handleClickPatient(item?.member);
              }
            }}
            key={item?.member?.id}
            className={`patient-list-item ${
              activePatient && activePatient.id === item?.member?.id
                ? "active"
                : ""
            } ${item.status.value === "canceled" ? "no-active" : ""}`}
            style={{
              cursor:
                item.status.value === "canceled" ? "not-allowed" : "pointer",
            }}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={item?.member?.avatar || ""} /> || <div></div>
              }
              title={
                <div className="patient-booking-name">
                  <span>
                    {item?.name}{" "}
                    <span> - {utils.calculateAge(item?.dob)} tuổi</span>
                  </span>
                  <div className="patient-booking-name-time">
                    <span>Stt khám: {item?.order_number}</span> -{" "}
                    <span>{item?.slot_start_time}</span>
                  </div>
                </div>
              }
              description={
                <Row className="patient-booking-detail">
                  <Col span={16} className="patient-booking-detail-info">
                    <Row>
                      <Col span={8}>
                        {item?.phone ? (
                          <>
                            <IconPhone />
                            <span className="patient-booking-detail-info-text">
                              {<SpanPhone phone={item?.phone} />}
                            </span>
                          </>
                        ) : null}
                      </Col>
                      <Col span={16}>
                        {item?.payment_method?.label ? <IconCheck /> : null}
                        <span className="patient-booking-detail-info-text">
                          {item?.payment_method?.label}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {item?.member?.address ? (
                          <div>
                            <IconLocation />
                            <span className="patient-booking-detail-info-text">
                              {item?.member?.address}
                            </span>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row style={{ alignItems: "center" }}>
                      <Col span={22}>
                        {item.payment_method.value === "banking" ? (
                          <Space size={[0, 8]} wrap>
                            {item.payment_confirm.value === "paid" ? (
                              <Tag
                                icon={<CheckCircleOutlined />}
                                color="success"
                              >
                                Đã thanh toán
                              </Tag>
                            ) : (
                              <Tag
                                color="#2db7f5"
                                onClick={() => {
                                  setUpdateModal(true);
                                  formUpdate.setFieldsValue({
                                    id: item.id,
                                  });
                                }}
                              >
                                Xác nhận thanh toán
                              </Tag>
                            )}
                          </Space>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col span={2}>
                        {item.status.value === "canceled" ? (
                          ""
                        ) : (
                          <div className="icon-delete">
                            <IconCancel
                              onClick={() => {
                                setDeleteModal(true);
                                formDelete.setFieldsValue({
                                  id: item.id,
                                });
                              }}
                              title={"Hủy đặt lịch"}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            />
          </List.Item>
        )}
      />
      <DeleteConfirmationModal
        isVisible={isDeleteModal}
        onCancel={handleCancel}
        onDelete={handleDelete}
        loading={loading}
        form={formDelete}
      />
      <PaymentConfirmationModal
        isVisible={isUpdateModal}
        onCancel={handleCancel}
        onFinishUpdate={handleUpdate}
        loading={false}
        form={formUpdate}
      />
    </>
  );
}

export default ListPatientBooking;
