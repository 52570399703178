import { Dropdown, MenuProps } from "antd";

function DropdownHeader({ itemsProps, HTMLProps }) {
  const items: MenuProps["items"] = itemsProps;

  return (
    <div>
      <Dropdown
        menu={{ items }}
        // trigger={["click"]}
        placement="bottomRight"
        arrow={{ pointAtCenter: true }}
      >
        {HTMLProps}
      </Dropdown>
    </div>
  );
}

export default DropdownHeader;
