import React from "react";
import { FaVial } from "react-icons/fa6";

function IconTest({ onClick }) {
  return (
    <span onClick={onClick} className="icon-container">
      <FaVial title="Xét nghiệm" style={{ color: "#1890ff" }} />
    </span>
  );
}

export default IconTest;
