import { Col, Empty, List, Modal, Pagination, Row, Spin, message } from "antd";
import { useEffect, useState } from "react";
import {
  AvatarName,
  CardProfile,
  FormatDate,
  IconDateCreate,
  IconViewDetail,
} from "src/components";
import { api } from "src/services";
import {
  PrescriptionAddCustom,
  PrescriptionDetail,
} from "src/views";

function ListPrescription({ patient_id, patient_name }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [prescriptionDetail, setPrescriptionDetail] = useState({});
  const [isModalOpenProfile, setIsModalOpenProfile] = useState(false);
  const [dataProfile, setDataProfile] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [number, setNumber] = useState(0);
  const limiter = 9;
  const [current, setCurent]: any = useState(1);
  const [pageSize, setPageSize]: any = useState(10);
  const [total, setTotal]: any = useState(10);

  const fetchPrescriptionOfPatient = async (current?: 1, pageSize?: 10) => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getAllPrescriptionOfPatient(
        patient_id,
        pageSize,
        current
      );
      if (rData) {
        setData(rData);
        setTotal(meta.total);
      } else {
        setData([]);
        setTotal(0);
      }
    } catch (error) {
      console.log(error);
      message.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrescriptionOfPatient(current, pageSize);
  }, [patient_id, current, pageSize]);

  const handleClickViewDetail = (prescription) => {
    setIsModalOpenDetail(true);
    setPrescriptionDetail(prescription);
  };


  const handleCancel = () => {
    setIsOpenModalCreate(false);
    setIsModalOpenProfile(false);
    setIsModalOpenDetail(false)
  };
  return (
    <>
      <List
        locale={{
          emptyText: <Empty description="Không tìm thấy toa thuốc" />,
        }}
        loading={loading}
        className="list-prescription bg-white"
        size="small"
        header={
          <>
            <span className="font-bold">Toa thuốc</span>
            {patient_id &&
              <div
                title="Thêm toa thuốc"
                onClick={() => {
                  setIsOpenModalCreate(true);
                  setFileUpload([]);
                  if (number === limiter) {
                    setNumber(number - 9);
                  } else {
                    setNumber(number + 1);
                  }
                }}
              >
                <span className="font-bold" style={{ cursor: "pointer" }}>Tạo mới</span>
              </div>
            }
          </>
        }
        footer={
          <>
            {total > 10 ? (
              <Pagination
                style={{ float: "right" }}
                size="small"
                defaultCurrent={1}
                total={total}
                current={current}
                defaultPageSize={10}
                pageSize={pageSize}
                onChange={(current, pageSize) => {
                  setCurent(current);
                  setPageSize(pageSize);
                }}
              />
            ) : (
              ""
            )}
          </>
        }
        bordered
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item
            className="list-prescription"
            onClick={() => handleClickViewDetail(item)}
            actions={[
              item?.created_at ? (
                <IconDateCreate
                  created_at={<FormatDate date={item?.created_at} />}
                />
              ) : null,
              <IconViewDetail onClick={() => handleClickViewDetail(item)} />,
            ]}
          >
            <Row>
              <Col
              // span={18}
              >
                <AvatarName
                  avatar={item?.doctor?.avatar}
                  name={item?.doctor?.name}
                  profileDoctor={(e) => {
                    e.stopPropagation();
                    setIsModalOpenProfile(true);
                    setDataProfile(item?.doctor);
                  }}
                  viewDetail={() => handleClickViewDetail(item)}
                />
              </Col>
              {/* <Col span={6}>
                {item?.created_at && (
                  <IconDateCreate
                    created_at={<FormatDate date={item?.created_at} />}
                  />
                )}
              </Col> */}
            </Row>
          </List.Item>
        )}
      />
      <Modal
        title="Thêm mới toa thuốc cho bệnh nhân"
        open={isOpenModalCreate}
        width={1300}
        onCancel={() => {
          setIsOpenModalCreate(false);
        }}
        footer={null}
        centered
        className="modal-scroll"
        destroyOnClose={true}
      >
        {/* <PrescriptionAdd
          fetchPrescriptionOfPatient={fetchPrescriptionOfPatient}
          patient_name={patient_name}
          patient_id={patient_id}
          handleCancel={handleCancel}
          setFileUpload={setFileUpload}
          fileUpload={fileUpload}
          number={number}
        /> */}
        <PrescriptionAddCustom
          fetchPrescriptionOfPatient={fetchPrescriptionOfPatient}
          patient_name={patient_name}
          patient_id={patient_id}
          handleCancel={handleCancel}
          setFileUpload={setFileUpload}
          fileUpload={fileUpload}
          number={number}
        />
      </Modal>

      <Modal
        title="Thông tin toa thuốc chi tiết của bệnh nhân"
        centered
        open={isModalOpenDetail}
        onOk={() => setIsModalOpenDetail(false)}
        onCancel={() => setIsModalOpenDetail(false)}
        footer={null}
        width={1000}
        className="modal-scroll"
      >
        <Spin spinning={loading}>
          <PrescriptionDetail
            prescription={prescriptionDetail}
            fetchPrescriptionOfPatient={fetchPrescriptionOfPatient}
            handleCancel={handleCancel}
          />
        </Spin>
      </Modal>
      <CardProfile
        doctorArr={dataProfile}
        isModalOpenProfile={isModalOpenProfile}
        setIsModalCancelProfile={() => setIsModalOpenProfile(false)}
      />
    </>
  );
}

export default ListPrescription;
