import { List, Modal } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { IconAdd, IconDateCreate } from "src/components";
import { utils } from "src/utils";
import { AddSignsAllergy } from "src/views";

function ListSignsAndAllergy({ patient, getPatient }) {
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
  const [params, setParams]: any = useState({});

  const handleClickModalAdd = (field) => {
    setIsOpenModalAdd(true);
    switch (field) {
      case "height":
        setParams({
          history: patient?.sign_allergy?.height || [],
          title: "Chiều Cao",
          unit: "cm",
          name: "height",
          type: "digit",
          max: 230,
          min: 30,
        });
        break;
      case "weight":
        setParams({
          history: patient?.sign_allergy?.weight || [],
          title: "Cân Nặng",
          unit: "kg",
          name: "weight",
          max: 500,
          min: 2,
          type: "digit",
        });
        break;
      case "blood_pressure":
        setParams({
          history: patient?.sign_allergy?.blood_pressure || [],
          title: "Huyết Áp",
          unit: "mmHg",
          name: "blood_pressure",
          type: "2digits",
        });
        break;
      case "blood_sugar":
        setParams({
          history: patient?.sign_allergy?.blood_sugar || [],
          title: "Đường Huyết",
          unit: "mg/dl",
          name: "blood_sugar",
          max: 500,
          min: 50,
          alertValue: 350,
          type: "digit",
        });
        break;
      case "blood_group":
        setParams({
          history: patient?.sign_allergy?.blood_group || [],
          title: "Nhóm Máu",
          name: "blood_group",
          hideHistory: true,
          type: "text",
        });
        break;
      case "allergy":
        setParams({
          history: patient?.sign_allergy?.allergy || [],
          title: "Dị Ứng",
          name: "allergy",
          hideHistory: true,
          textArea: true,
          type: "textarea",
        });
        break;
      default:
        break;
    }
  };
  return (
    <>
      <List
        className="signs-and-allergy bg-white"
        size="small"
        header={<div className="font-bold">Sinh dị và hiệu ứng</div>}
        bordered
      >
        <List.Item>
          <div className="signs-and-allergy-item">
            <div className="signs-and-allergy-item-title">
              <span className="font-bold">Chiều cao: </span>
              <span>
                {(_.last(patient?.sign_allergy?.height) as { value?: number })
                  ?.value ? (
                  <>
                    {
                      (
                        _.last(patient?.sign_allergy?.height) as {
                          value: number;
                        }
                      )?.value
                    }{" "}
                    cm
                  </>
                ) : (
                  "không có dữ liệu"
                )}
              </span>
            </div>
            <IconDateCreate
              created_at={utils.formatDate(
                (_.last(patient?.sign_allergy?.height) as { date: Date })?.date
              )}
            />
          </div>
          <IconAdd
            title="Thêm chiều cao"
            onClick={() => handleClickModalAdd("height")}
          />
        </List.Item>
        <List.Item>
          <div className="signs-and-allergy-item">
            <div className="signs-and-allergy-item-title">
              <span className="font-bold">Cân nặng: </span>
              <span>
                {(_.last(patient?.sign_allergy?.weight) as { value: number })
                  ?.value ? (
                  <>
                    {
                      (
                        _.last(patient?.sign_allergy?.weight) as {
                          value: number;
                        }
                      )?.value
                    }{" "}
                    kg
                  </>
                ) : (
                  "không có dữ liệu"
                )}
              </span>
            </div>
            <IconDateCreate
              created_at={utils.formatDate(
                (_.last(patient?.sign_allergy?.weight) as { date: Date })?.date
              )}
            />
          </div>
          <IconAdd
            title="Thêm câng nặng"
            onClick={() => handleClickModalAdd("weight")}
          />
        </List.Item>
        <List.Item>
          <div className="signs-and-allergy-item">
            <div className="signs-and-allergy-item-title">
              <span className="font-bold">BMI: </span>
              {patient?.sign_allergy?.bmi
                ? patient?.sign_allergy?.bmi
                : "không có dữ liệu"}
            </div>
          </div>
        </List.Item>
        <List.Item>
          <div className="signs-and-allergy-item">
            <div className="signs-and-allergy-item-title">
              <span className="font-bold">Huyết áp: </span>
              <span>
                {(
                  _.last(patient?.sign_allergy?.blood_pressure) as {
                    value: number;
                  }
                )?.value ? (
                  <>
                    {`${
                      (
                        _.last(patient?.sign_allergy?.blood_pressure) as {
                          value: { fnum?: number; snum?: number };
                        }
                      )?.value?.fnum || 0
                    }/${
                      (
                        _.last(patient?.sign_allergy?.blood_pressure) as {
                          value: { fnum?: number; snum?: number };
                        }
                      )?.value?.snum || 0
                    }`}{" "}
                    mmHg
                  </>
                ) : (
                  "Không có dữ liệu"
                )}
              </span>
            </div>
            <IconDateCreate
              created_at={utils.formatDate(
                (
                  _.last(patient?.sign_allergy?.blood_pressure) as {
                    date: Date;
                  }
                )?.date
              )}
            />
          </div>
          <IconAdd
            title="Thêm huyết áp"
            onClick={() => handleClickModalAdd("blood_pressure")}
          />
        </List.Item>
        <List.Item>
          <div className="signs-and-allergy-item">
            <div className="signs-and-allergy-item-title">
              <span className="font-bold">Đường huyết:</span>
              <span>
                {(
                  _.last(patient?.sign_allergy?.blood_sugar) as {
                    value: number;
                  }
                )?.value ? (
                  <>
                    {parseFloat(
                      _.last(
                        patient?.sign_allergy?.blood_sugar as {
                          value: string;
                        }[]
                      )?.value
                    )}{" "}
                    mg/dl
                  </>
                ) : (
                  "không có dữ liệu"
                )}
              </span>
            </div>
            <IconDateCreate
              created_at={utils.formatDate(
                (_.last(patient?.sign_allergy?.blood_sugar) as { date: Date })
                  ?.date
              )}
            />
          </div>
          <IconAdd
            title="Thêm đường huyết"
            onClick={() => handleClickModalAdd("blood_sugar")}
          />
        </List.Item>
        <List.Item>
          <div className="signs-and-allergy-item">
            <div className="signs-and-allergy-item-title">
              <span className="font-bold">Nhóm máu: </span>
              <span>
                {patient?.sign_allergy?.blood_group ? (
                  <span>{patient?.sign_allergy?.blood_group} </span>
                ) : (
                  "Không có dữ liệu"
                )}
              </span>
            </div>
          </div>
          <IconAdd
            title="Thêm nhóm máu"
            onClick={() => handleClickModalAdd("blood_group")}
          />
        </List.Item>
        <List.Item>
          <div className="signs-and-allergy-item">
            <div className="signs-and-allergy-item-title">
              <span className="font-bold">Dị ứng: </span>
              <span>
                {patient?.sign_allergy?.allergy ? (
                  <>{patient?.sign_allergy?.allergy} </>
                ) : (
                  "không có dữ liệu"
                )}
              </span>
            </div>
          </div>
          <IconAdd
            title="Thêm dị ứng"
            onClick={() => handleClickModalAdd("allergy")}
          />
        </List.Item>
      </List>
      <Modal
        title={params.title}
        open={isOpenModalAdd}
        centered
        onCancel={() => setIsOpenModalAdd(false)}
        footer={null}
        className="modal-scroll"
      >
        <AddSignsAllergy
          patient={patient}
          params={params}
          getPatient={getPatient}
          setIsOpenModalAdd={setIsOpenModalAdd}
        />
      </Modal>
    </>
  );
}

export default ListSignsAndAllergy;
