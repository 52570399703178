import { PlusOutlined } from "@ant-design/icons";
import { Form, Upload } from "antd";
import { isEmpty } from "lodash";

function UploadSection({
  isImageConfirmationNeeded,
  onBeforeUpload,
  setCheckFile,
  messageError,
  onRemove,
}) {
  if (isImageConfirmationNeeded) {
    return (
      <Form.Item>
        <Upload
          listType="picture-card"
          showUploadList={true}
          beforeUpload={onBeforeUpload}
          onChange={(e) => {
            setCheckFile(e.fileList.length);
          }}
          onRemove={onRemove}
          customRequest={() => {}}
          multiple={true}
          accept=".jpg, .jpeg, .png"
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 4 }}>Upload</div>
          </div>
        </Upload>
        <label style={{ color: "red" }}>
          {!isEmpty(messageError) ? messageError : ""}
        </label>
      </Form.Item>
    );
  }
  return null;
}

export default UploadSection;
