import { Button, Card, Form } from "antd";
import React from "react";

const CardCopy = ({ loading, onClick }) => {
  //onClick();
  return (
    <Card
      size="small"
      style={{
        marginTop: "1rem",
      }}
      bordered={false}
      onClick={() => onClick()}
      // title="Xuất bản"
    >
      <Form.Item>
        <Button type="primary" loading={loading}>
          Sao chép toa thuốc
        </Button>
      </Form.Item>
    </Card>
  );
};

export default CardCopy;
