import { ReactElement } from "react";
import {
  BookFilled,
  CalendarOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import {
  FaUserDoctor,
  FaHospitalUser,
  FaHeartPulse,
  FaBed,
  FaUserPlus,
} from "react-icons/fa6";

const getItem = (
  label: string,
  key: string,
  icon: ReactElement,
  children?: any[]
) => {
  return {
    key,
    icon,
    children,
    label,
  };
};

const items = [
  // getItem("Dashboard", "", <PieChartOutlined />),
  getItem("Lịch khám", "schedules", <CalendarOutlined />),

  getItem("Bệnh nhân của tôi", "my-patient", <FaHospitalUser />),
  // getItem("Gói dịch vụ", "service", <CustomerServiceOutlined />),
  getItem("Kết quả dịch vụ", "service-result", <CustomerServiceOutlined />),
  getItem("Từ điển xét nghiệm", "dictionary", <BookFilled />),
  getItem("Phác đồ điều trị", "TreatmentProtocol", <FaHeartPulse />),

  getItem("Giới thiệu", "introduce", <FaUserPlus />, [
    getItem("Bác sĩ giới thiệu", "doctor-referrers", <FaUserDoctor />),
    getItem("Bệnh nhân được giới thiệu", "patient-referrers", <FaBed />),
  ]),
];

export default items;
