import { Card, Skeleton, List, Tag } from "antd";
import { useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

function AlbumDetail({
  fetchAlbumDetail,
  albumId,
  images,
  loading,
  files,
  random,
}) {
  useEffect(() => {
    fetchAlbumDetail();
  }, [albumId, random]);

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <div className="document-picture">
      {files.length > 0 && (
        <Card size="small" loading={loading} title="File">
          <Skeleton loading={loading}>
            <List
              size="small"
              dataSource={files}
              renderItem={(item: any) => (
                <div>
                  {item.url ? (
                    <List.Item>
                      <Tag>
                        <div className="tag-subclinical">
                          <a href={item.url} target="_blank">
                            {item.url}
                          </a>
                        </div>
                      </Tag>
                    </List.Item>
                  ) : (
                    ""
                  )}
                </div>
              )}
            />
          </Skeleton>
        </Card>
      )}
      {images.length > 0 && (
        <Card
          size="small"
          style={{
            marginTop: 16,
            height: "unset",
          }}
          title="Ảnh:"
        >
          <Skeleton loading={loading}>
            <div>
              <ImageList
                variant="quilted"
                cols={Math.min(images.length, 5)}
                gap={16}
                rowHeight={
                  images.length === 4
                    ? 150
                    : images.length === 3
                    ? 200
                    : images.length === 2
                    ? 250
                    : images.length === 1
                    ? 500
                    : 121
                }
              >
                {images.map((item: any) => (
                  <div>
                    {item ? (
                      <ImageListItem
                        key={item.url}
                        cols={item.cols || 1}
                        rows={item.rows || 1}
                        className="list-images"
                      >
                        <img
                          {...srcset(item.url, 121, item.cols, item.rows)}
                          alt={item.url}
                          loading="lazy"
                          onClick={() => window.open(item.url)}
                          style={{ cursor: "pointer" }}
                        />
                      </ImageListItem>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </ImageList>
            </div>
          </Skeleton>
        </Card>
      )}
    </div>
  );
}

export default AlbumDetail;
