import React from "react";
import { Button, Form, Input, Modal } from "antd";

const EditScheduleModal = ({
  open,
  onCancel,
  onFinish,
  form,
  initialValues,
  isUpdating,
}) => {
  return (
    <Modal title="Chỉnh sửa" open={open} footer={null} onCancel={onCancel}>
      <Form onFinish={onFinish} form={form} initialValues={initialValues}>
        <Form.Item name="id" hidden>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="date" hidden>
          <Input type="date" />
        </Form.Item>

        <Form.Item
          label="Số người khám: "
          name="patient_quantity"
          rules={[
            {
              required: true,
              message: "Số bệnh nhân không được để trống",
            },
          ]}
        >
          <Input placeholder="Số bệnh nhân" />
        </Form.Item>
        <Form.Item
          style={{ textAlign: "right", marginBottom: "unset" }}
          name="repeat"
        >
          <Button
            type="primary"
            loading={isUpdating}
            style={{ marginRight: "0.5rem" }}
            onClick={() => {
              const updatedValues = {
                ...form.getFieldsValue(),
                repeat: "onlyday",
              };
              form.setFieldsValue(updatedValues);
              form.submit();
            }}
          >
            Chỉ một ngày
          </Button>
          <Button
            type="primary"
            style={{ marginRight: "0.5rem" }}
            loading={isUpdating}
            onClick={() => {
              const updatedValues = {
                ...form.getFieldsValue(),
                repeat: "daily",
              };
              form.setFieldsValue(updatedValues);
              form.submit();
            }}
          >
            Toàn bộ
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditScheduleModal;
