import {ActionTypes} from './actions';

type State = Object | null;
const initState: State = null;

const reducer = (state: State = initState, action: ActionTypes) => {
  switch (action.type) {
    case 'SAVE_SETTING':
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
