import { Button, Checkbox, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { allActions } from "src/redux";
import { api } from "src/services";
import CryptoJS from "crypto-js";
import { images } from "src/assets";

function Login(props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [rememberChecked, setRememberChecked] = useState<boolean>(true);

  useEffect(() => {
    const phone = localStorage.getItem("phone");
    const password = localStorage.getItem("password");

    if (phone && password) {
      const decryptedPassword = CryptoJS.AES.decrypt(
        password,
        "secret key"
      ).toString(CryptoJS.enc.Utf8);
      form.setFieldsValue({
        phone,
        password: decryptedPassword,
      });
    }
  }, [rememberChecked]);

  const onFinish = async (values: { phone: string; password: string }) => {
    try {
      setLoading(true);
      const response: { data: any } = await api.login(values);
      const {
        accessToken = null,
        userData = null,
        message: msg = null,
      } = response?.data;

      if (accessToken && userData) {
        message.success(msg ? msg : "Login successfully!");

        localStorage.setItem("access_token", accessToken);

        dispatch(allActions.user.saveUser(userData));

        history.push("/");

        if (rememberChecked) {
          const encryptedPassword = CryptoJS.AES.encrypt(
            values.password,
            "secret key"
          ).toString();
          localStorage.setItem("password", encryptedPassword);
          localStorage.setItem("phone", values.phone);
        } else {
          localStorage.removeItem("password");
          localStorage.removeItem("phone");
        }
      } else {
        message.error("Data invalid!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  const handleCheckboxChange = (e: any) => {
    setRememberChecked(e.target.checked);
  };

  return (
    <div className="auth-box">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={images.LogoImedical.logoLogin}
          style={{ width: "25%", marginBottom: 20 }}
        ></img>
      </div>
      <h6>
        <p>SIGN IN BELOW:</p>
      </h6>
      <Form
        form={form}
        initialValues={{
          remember: true,
        }}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          label="Username"
          name="phone"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox onChange={handleCheckboxChange}>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Link
        style={{
          fontSize: 12,
        }}
        to="/auth/password/reset"
      >
        Lost your password?
      </Link>
    </div>
  );
}

export default Login;
