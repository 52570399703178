import { Button, Form, Input, Modal, Table, message } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { api } from "src/services";
import { contains, utils } from "src/utils";
import _ from "lodash";
import {
  AvatarName,
  BreadcrumbComponent,
  IconCheckNow,
  IconDelete,
  IconTest,
  ModalDelete,
  SpanMailTo,
  SpanPhone,
} from "src/components";
const { confirm } = Modal;

export default function MyPatient() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [listPatients, setListPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [formDelete] = Form.useForm();
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    fetchPatient();
  }, [searchValue]);

  const handleViewDetailPatient = (id) => {
    history.push(`${path}/schedule-detail/${id}`);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Tên",
      dataIndex: "avatar",
      key: "avatar",
      width: 200,
      render: (avatar, record) => (
        <div>
          <AvatarName
            avatar={avatar}
            name={record?.name}
            profileDoctor={() => {
              history.push(`/my-patient/schedule-detail/${record.id}`);
            }}
            viewDetail={() => handleViewDetailPatient(record?.id)}
          />
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      render: (text: string, record: any) => <SpanMailTo mail={text} />,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      width: 150,
      render: (text: string, record: any) => <SpanPhone phone={text} />,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      // width: 300,
    },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      width: 100,
      render: (text: string, record: any) => (
        <span>{text === "male" ? "Nam" : text === "female" ? "Nữ" : ""}</span>
      ),
    },
    {
      title: "Tuổi",
      dataIndex: "dob",
      key: "dob",
      width: 100,
      render: (text: string, record: any) => utils.calculateAge(text),
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      width: 130,
      render: (text: string, record: any) => (
        <div>
          <IconTest onClick={() => handleClickTest(record.id)} />
          <IconCheckNow onClick={() => handleViewDetailPatient(record?.id)} />
          <IconDelete
            onClick={() => {
              setOpenModalDelete(true);
              formDelete.setFieldsValue({
                id: record.id,
                status: record.status,
              });
            }}
          />
        </div>
      ),
    },
  ];
  const handleClickTest = (id) => {
    history.push(`${path}/order-patient-test/${id}`);
  };

  const fetchPatient = async (page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const { data: rPatitent, meta }: any = await api.getMyPatient(contains.patientType, page, searchValue, pageSize);
      setLoading(false);
      if (rPatitent) {
        let patientArr = rPatitent.map((el: any) => ({ key: el?.id, ...el }));
        setTableParams({
          ...tableParams,
          pagination: {
            current: page,
            pageSize: meta.per_page,
            total: meta.total,
          },
        });
        setListPatients(patientArr);
      }
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchPatient(pagination.current, pagination.pageSize);
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRowKeys);
      setSelectedRows(selectedRows.map((el: any) => el?.id));
    },
    onSelect: (record: any, selected: any, selectedRows: any) => {
      setSelectedRows(selectedRows.map((el: any) => el?.id));
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      setSelectedRows(selectedRows.map((el: any) => el?.id));
    },
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteMyPatient(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchPatient();
      setLoading(false);
      handleCanel();
    }
  };

  const handleCanel = () => {
    setOpenModalDelete(false);
  };

  const onConfirmDelete = () => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa không?",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const response: any = await api.deleteAllMyPatient(selectedRows);
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          console.log(error);
          message.error(error);
        } finally {
          setSelectedRows([]);
          fetchPatient();
        }
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };

  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "/my-patient", name: "Bệnh nhân của tôi" },
  ];

  return (
    <div className="admin-table my-patient">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div className="wp-action" style={{ marginBottom: "1rem" }}>
          <div className="wp-action-left">
            <Button
              onClick={onConfirmDelete}
              disabled={selectedRows.length === 0}
              style={{
                marginRight: ".5rem",
              }}
              type="primary"
              danger
            >
              Xoá
            </Button>
            <Input
              style={{
                width: 250,
                marginRight: ".5rem",
              }}
              allowClear
              onChange={_.debounce(function (e: any) {
                setSearchValue(e.target.value);
              }, 300)}
              placeholder="Tìm kiếm"
            />
          </div>
          <div className="wp-action-right"></div>
        </div>
        <Table
          rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={listPatients}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
        <ModalDelete
          open={openModalDelete}
          cancel={handleCanel}
          form={formDelete}
          handleSubmit={handleDelete}
          loading={loading}
          title={"Xóa"}
        />
      </div>
    </div>
  );
}
