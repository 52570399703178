import React from "react";
import { FaFileImage } from "react-icons/fa6";

function IconDocument({ content }) {
  return (
    <div className="icon-document-container align-center size-xs" >
      <FaFileImage style={{ color: "#6c757d" }} />
      <span style={{ paddingLeft: "2px" }}>{content}</span>
    </div>
  );
}

export default IconDocument;
