import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getService<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/services`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getServiceResult<T>(
  searchValue: string = "",
  per_page: number,
  page: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(
        `v1/service-result?q=${searchValue}&per_page=${per_page}&page=${page}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getServiceResultPatient<T>(
  patientId: number
): NetworkPromiseResponse<T> {
  console.log(patientId);

  return new Promise((resolve, reject) => {
    network
      .get(`v1/service-result/${patientId}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function createService<T>(value): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/services/create`, value)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteService<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/services/${id}`) // Use the `id` parameter in the URL
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
function deleteAllService<T>(ids: any): NetworkPromiseResponse<T> {
  const jsonIds = JSON.stringify(ids);
  return new Promise((resolve, reject) => {
    network
      .request({
        url: "v1/services/all",
        method: "delete",
        data: jsonIds,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function updateService<T>(
  id: number,
  formData: any
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/services/edit/${id}`, formData) // Use the `id` parameter in the URL
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getService,
  createService,
  deleteService,
  updateService,
  deleteAllService,
  getServiceResult,
  getServiceResultPatient,
};

export default data;
