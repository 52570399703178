import { Form, Radio } from "antd";
import { useEffect, useState } from "react";

function TestPackage({ name, label, rules, data }) {
  const [selectedPackageId, setSelectedPackageId] = useState();

  useEffect(() => {
    let packageDefault = data.find((item) => item?.is_default === 1);
    setSelectedPackageId(packageDefault?.id);
  }, []);

  const handleRadioChange = (e) => {
    setSelectedPackageId(e.target.value);
  };

  return (
    <>
      {selectedPackageId && (
        <Form.Item
          name={name}
          label={label}
          rules={rules}
          initialValue={selectedPackageId}
        >
          <Radio.Group defaultValue={selectedPackageId} buttonStyle="solid">
            {data.map((item) => (
              <Radio.Button
                key={item.id}
                value={item.id}
                style={{
                  marginBottom: "1rem",
                  width: "100%",
                  background:
                    selectedPackageId === item.id ? item.color : "initial",
                }}
                onChange={handleRadioChange}
              >
                {item.name} - {item.price_formated}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      )}
    </>
  );
}

export default TestPackage;
