import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import React from "react";

const ModalDelete = ({ open, cancel, form, handleSubmit, loading, title }) => {
  return (
    <>
      <Modal title={title} open={open} footer={null} onCancel={cancel}>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item name="id">
            <div>
              <ExclamationCircleOutlined /> Bạn có chắc chắn muốn xóa?
              <input type="number" hidden />
            </div>
          </Form.Item>
          <Form.Item style={{ textAlign: "right", marginBottom: "unset" }}>
            <Button type="primary" danger loading={loading} htmlType="submit">
              Có
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalDelete;
