import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getAllTestByIdPatient<T>(
  id: number,
  page: number,
  per_page: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/order-patient-test/all/${id}?page=${page}&per_page=${per_page}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getAllTestByIdPatient,
};

export default data;
