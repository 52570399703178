import { List, Tag, Card, Skeleton, Modal, message, Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import { FaXmark } from "react-icons/fa6";

const { confirm } = Modal;

function ServiceResultItem({ serviceResultDetail, subclinicals }) {
  const [loading, setLoading] = useState(false);
  const [subclinicalItem, setSubclinicalItem] = useState([]);
  const handleClose = (recordId, file_url) => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa không?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        setLoading(true);
        try {
          const response: any = await api.deleteSubclinicals(
            file_url,
            "file",
            recordId
          );
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          message.error(error.message);
          console.log(error);
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };
  const deleteImage = (recordId, file_url) => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa không?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        setLoading(true);
        try {
          const response: any = await api.deleteSubclinicals(
            file_url,
            "image",
            recordId
          );
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          message.error("Xoá không thành công", error);
        } finally {
          setLoading(false);
          // setServiceResultDetail(serviceResultDetail);
        }
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };
  useEffect(() => {
    setSubclinicalItem(subclinicals["result"]);
    // console.log("subclinicalItem", subclinicalItem);
  });
  return (
    <div className="admin-table OrderTest">
      <div className="flex-fill">
        <div>
          <Card title="Kết quả:" size="small" style={{ marginBottom: 16 }}>
            <List
              dataSource={subclinicalItem}
              renderItem={(item: any) => (
                <div>
                  {item.file.length > 0 && (
                    <div>
                      <span>{item.doctor.name}</span>
                      <List
                        dataSource={item.file}
                        renderItem={(itemfile: any) => (
                          <List.Item>
                            <Tag>
                              <a href={itemfile.url} target="_blank">
                                {itemfile.url}
                              </a>
                            </Tag>
                            <Button
                              type="primary"
                              danger
                              loading={loading}
                              onClick={() =>
                                handleClose(item.id, itemfile.file_url)
                              }
                            >
                              <DeleteOutlined />
                              Xoá
                            </Button>
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
            />
          </Card>
          <Card
            title="Ảnh:"
            size="small"
            loading={loading}
            style={{ marginBottom: 16 }}
          >
            <List
              dataSource={subclinicalItem}
              renderItem={(item: any) => (
                <div>
                  {item.images.length > 0 && (
                    <div className="service__result-image">
                      <span className="service__result-image-member">
                        {item.doctor.name}
                      </span>
                      <List
                        dataSource={item.images}
                        renderItem={(itemImage: any) => (
                          <List.Item>
                            {itemImage.url ? (
                              <ImageListItem
                                key={itemImage.url}
                                className="list-images"
                              >
                                <img
                                  className="list-images__result-item"
                                  src={itemImage.url}
                                  alt={itemImage.url}
                                  onClick={() => window.open(itemImage.url)}
                                  style={{ cursor: "pointer" }}
                                />
                                <ImageListItemBar
                                  sx={{
                                    background:
                                      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                                  }}
                                  title={itemImage.title}
                                  position="top"
                                  actionIcon={
                                    <IconButton
                                      sx={{ color: "white" }}
                                      aria-label={`star ${itemImage.title}`}
                                    >
                                      <FaXmark
                                        onClick={() =>
                                          deleteImage(
                                            item.id,
                                            itemImage.file_url
                                          )
                                        }
                                      />
                                    </IconButton>
                                  }
                                  actionPosition="right"
                                />
                              </ImageListItem>
                            ) : (
                              ""
                            )}
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ServiceResultItem;
