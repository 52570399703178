import { Modal, Form, Button } from "antd";

const DeleteConfirmationModal = ({
  isVisible,
  onCancel,
  onDelete,
  loading,
  form,
}) => {
  return (
    <Modal
      open={isVisible}
      title="Hủy đặt lịch"
      onCancel={onCancel}
      footer={null}
    >
      <Form onFinish={onDelete} form={form}>
        <Form.Item name="id">
          <div>
            <label style={{ marginBottom: "10px" }}>
              Bạn có chắc chắn muốn hủy không?
            </label>
            <input type="text" hidden />
          </div>
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Item style={{ textAlign: "right", marginBottom: "unset" }}>
            <Button type="primary" danger loading={loading} htmlType="submit">
              Hủy
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default DeleteConfirmationModal;
