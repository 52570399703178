import { PlusCircleOutlined } from "@ant-design/icons";
import React from "react";

function IconAddAmount({ onClick }) {
  return (
    <span className="icon-container">
      <PlusCircleOutlined
        style={{ color: "blue" }}
        title="Thêm số lượng"
        onClick={onClick}
      />
    </span>
  );
}

export default IconAddAmount;
