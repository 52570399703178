import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Modal, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { AddButton, BreadcrumbComponent, CreateSamplePrescriptionModal, IconEdit, IconDelete, ModalDelete, OnDeleteButton, SearchInput, DetailSamplePrescriptionModal, EditSamplePrescriptionModal, IconViewDetail } from 'src/components';
import { api } from 'src/services';

function SamplePrescription(props) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
    const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
    const [isModalEditVisible, setIsModalEditVisible] = useState(false);
    const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
    const [dataSamplePrescription, setDataSamplePresription] = useState([]);
    const [samplePrescriptionDetail, setSamplePrescriptionDetail]: any = useState({});
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [searchValue, setSearchValue] = useState("");
    const { confirm } = Modal;
    const [formDelete] = Form.useForm();
    const [tableParams, setTableParams] = useState<any>({
        pagination: {
            current: 1,
            pageSize: 1,
        },
    });

    const breadcrumbItems = [
        { path: "/", name: "Dashboard" },
        { path: "#", name: "Cài đặt toa thuốc mẫu" },
    ];

    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRowKeys);
            setSelectedRows(selectedRows.map((el) => el?.id));
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows.map((el) => el?.id));
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows.map((el) => el?.id));
        },
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Tên",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            key: "note",
        },
        {
            title: "Tổng tiền",
            dataIndex: "total_format",
            key: "total_format",
        },
        {
            title: "Ngày",
            dataIndex: "date_format",
            key: "date_format",
        },
        {
            title: "Hoạt động",
            render: (text: string, record: any) => (
                <>
                    {/* <IconViewDetail
                        onClick={() => handleClickDetail(record)}
                    /> */}
                    <IconEdit
                        onClick={() => {
                            handleClickEdit(record)
                        }}
                    />
                    <IconDelete
                        onClick={() => {
                            setIsModalDeleteVisible(true);
                            formDelete.setFieldsValue({
                                id: record.id,
                                status: record.status,
                            });
                        }}
                    />
                </>
            ),
        },
    ];

    const handleClickDetail = (item) => {
        setIsModalUpdateVisible(true);
        setSamplePrescriptionDetail(item);
    }

    const handleClickEdit = (item) => {
        setIsModalEditVisible(true);
        setSamplePrescriptionDetail(item);
    }

    const fetchSamplePrescription = async (page = 1, pageSize = 10) => {
        try {
            setLoading(true);
            const res: any = await api.getSamplePrescription(page, pageSize, searchValue);
            if (res && res.data) {
                let samplePrescriptionArr = res.data.map((el: any) => ({ key: el.id, ...el }));
                setTableParams({
                    ...tableParams,
                    pagination: {
                        current: page,
                        pageSize: res.meta.per_page,
                        total: res.meta.total
                    },
                });
                setDataSamplePresription(samplePrescriptionArr);
            } else {
                setDataSamplePresription([]);
            }
        } catch (error) {
            message.error(error.message);
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        fetchSamplePrescription(pagination.current, pagination.pageSize);
    };

    useEffect(() => {
        fetchSamplePrescription();
    }, [searchValue]);

    const handleCancel = () => {
        setIsModalCreateVisible(false);
        setIsModalUpdateVisible(false);
        setIsModalEditVisible(false);
        setIsModalDeleteVisible(false);
        form.resetFields();
    }

    const handleDeleteSamplePrescription = async (values) => {
        try {
            setLoading(true);
            const response: any = await api.deleteSamplePrescription(values.id);
            if (response.error) {
                message.error(response.message);
            } else {
                message.success(response.message);
            }
        } catch (error) {
            console.log(error);
            message.error(error.message);
        } finally {
            fetchSamplePrescription();
            handleCancel();
            setLoading(false);
        }
    };

    const onConfirmDelete = () => {
        confirm({
            okText: "Delete",
            cancelText: "Cancel",
            title: "Bạn có chắc chắn muốn các toa thuốc mẫu này không",
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
                try {
                    const response: any = await api.deleteListSamplePrescription(selectedRows);
                    if (response.error) {
                        message.error(response.message);
                    } else {
                        message.success(response.message);
                    }
                } catch (error) {
                    message.error(error.message);
                } finally {
                    setLoading(false);
                    fetchSamplePrescription();
                    setSelectedRows([]);
                }
            },
            onCancel() {
                console.log("Cancel delete");
            },
        });
    };


    return (
        <div className="admin-table sample-prescription">
            <BreadcrumbComponent items={breadcrumbItems} />
            <div className="flex-fill">
                <div className='wp-action'>
                    <div className="wp-action-left">
                        <OnDeleteButton
                            onClick={onConfirmDelete}
                            disabled={selectedRows.length === 0}
                            title={"Xóa"}
                        />
                        <SearchInput
                            onSearch={(value) => setSearchValue(value)}
                            title={"Tìm kiếm toa thuốc mẫu"}
                            width={250}
                        />
                    </div>
                    <div className="wp-action-right">
                        <AddButton
                            onClick={() => {
                                setIsModalCreateVisible(true);
                                form.resetFields();
                            }}
                            title={"Thêm mới"}
                        />
                    </div>
                </div>
                <Table
                    rowSelection={{ ...rowSelection }}
                    columns={columns}
                    dataSource={dataSamplePrescription}
                    loading={loading}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                />
            </div>
            <CreateSamplePrescriptionModal
                isModalCreateVisible={isModalCreateVisible}
                handleCancel={handleCancel}
                form={form}
                fetchSamplePrescription={fetchSamplePrescription}
            />
            {isModalEditVisible &&
                <EditSamplePrescriptionModal
                    isModalEditVisible={isModalEditVisible}
                    handleCancel={handleCancel}
                    form={form}
                    fetchSamplePrescription={fetchSamplePrescription}
                    samplePrescriptionDetail={samplePrescriptionDetail}
                />
            }
            {isModalUpdateVisible &&
                <DetailSamplePrescriptionModal
                    isModalUpdateVisible={isModalUpdateVisible}
                    handleCancel={handleCancel}
                    samplePrescriptionDetail={samplePrescriptionDetail}
                />
            }
            <ModalDelete
                open={isModalDeleteVisible}
                cancel={handleCancel}
                form={formDelete}
                handleSubmit={handleDeleteSamplePrescription}
                loading={loading}
                title={"Xóa toa thuốc mẫu"}
            />
        </div>
    );
}

export default SamplePrescription;