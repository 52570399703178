import React from "react";
import { Input } from "antd";
import _ from "lodash";

const SearchInput = ({ onSearch, title, width }) => {
  const handleSearch = _.debounce((value) => onSearch(value), 500);

  return (
    <Input
      style={{
        width: width,
        marginRight: ".5rem",
        marginBottom: ".5rem",
      }}
      allowClear
      onChange={(e) => handleSearch(e.target.value)}
      placeholder={title}
    />
  );
};

export default SearchInput;
