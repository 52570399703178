import { Image, Row, Col } from "antd";

function DoctorReferrersDetail(props) {
  const { rPatitent } = props;

  return (
    <div className="admin-table">
      <div className="flex-fill">
        <div>
          <Row>
            <Col span={4}>
              <Image src={rPatitent.avatar} style={{ width: "80px" }} />
            </Col>
            <Col span={20}>
              <div className="info-group">
                <div className="info-group-title">Họ và tên:</div>
                {rPatitent?.name ? (
                  <div className="info-group-content">{rPatitent.name}</div>
                ) : null}
              </div>

              <div className="info-group">
                <div className="info-group-title">Giới tính:</div>
                {rPatitent?.gender ? (
                  <div className="info-group-content">
                    {rPatitent.gender === "male" ? "Nam" : "Nữ"}
                  </div>
                ) : null}
              </div>

              <div className="info-group">
                <div className="info-group-title">Chuyên khoa:</div>
                {rPatitent?.specialist ? (
                  <div className="info-group-content">
                    {rPatitent.specialist}
                  </div>
                ) : null}
              </div>

              {rPatitent?.phone ? (
                <div className="info-group">
                  <div className="info-group-title">Số điện thoại:</div>
                  <div className="info-group-content">{rPatitent.phone}</div>
                </div>
              ) : null}

              <div className="info-group">
                <div className="info-group-title">Email:</div>
                {rPatitent?.email ? (
                  <div className="info-group-content">{rPatitent.email}</div>
                ) : null}
              </div>

              <div className="info-group">
                <div className="info-group-title">Tên bệnh viện:</div>
                {rPatitent?.hospital_name ? (
                  <div className="info-group-content">
                    {rPatitent.hospital_name}
                  </div>
                ) : null}
              </div>

              <div className="info-group">
                <div className="info-group-title">Địa chỉ bệnh viện:</div>
                {rPatitent?.hospital_address ? (
                  <div className="info-group-content">
                    {rPatitent.hospital_address}
                  </div>
                ) : null}
              </div>

              <div className="info-group">
                <div className="info-group-title">Tên ngân hàng:</div>
                {rPatitent?.bank_name ? (
                  <div className="info-group-content">
                    {rPatitent.bank_name}
                  </div>
                ) : null}
              </div>

              <div className="info-group">
                <div className="info-group-title">Số tài khoản ngân hàng:</div>
                {rPatitent?.bank_number ? (
                  <div className="info-group-content">
                    {rPatitent.bank_number}
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default DoctorReferrersDetail;
