import { Modal, Table, message, Spin, Form, Button } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { api } from "src/services";
import {
  AvatarName,
  BreadcrumbComponent,
  CardProfile,
  IconCheckNow,
  IconDelete,
  IconTest,
  SearchInput,
  SpanMailTo,
  SpanPhone,
} from "src/components";

export default function PatientReferrers() {
  // const dateFormat = "DD-MM-YYYY";
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpenProfile, SetIsModalOpenProfile] = useState(false);
  const [isOpenModalDelete, setOpenModalDelete] = useState(false);
  const [dataProfile, SetDataProfile] = useState("");
  const [formDelete] = Form.useForm();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    fetchData();
  }, [searchValue]);

  const fetchData = async (page?: 1) => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getAllPatientReferrers(
        10,
        page
      );
      if (rData) {
        let dataArr = rData.map((el: any) => ({ key: el.id, ...el }));
        setTableParams({
          ...tableParams,
          pagination: {
            current: page,
            pageSize: meta.per_page,
            total: meta.total,
          },
        });
        setData(dataArr);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const filteredData = data.filter(
    (category) =>
      category.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      category.phone.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleNameClickProfile = async (record) => {
    SetIsModalOpenProfile(true);
    SetDataProfile(record);
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Bệnh nhân",
      dataIndex: "avatar",
      key: "avatar",
      render: (avatar, record) => (
        <div>
          <AvatarName
            avatar={avatar}
            name={record?.name}
            profileDoctor={() => {
              history.push(`/my-patient/schedule-detail/${record.id}`);
            }}
            viewDetail={() => {
              history.push(`${path}/schedule-detail/${record.id}`);
            }}
          />
        </div>
      ),
    },
    // {
    //   title: "Của Bác sĩ",
    //   dataIndex: "",
    //   key: "",
    //   render: (listPatients: any) => (
    //     <div onClick={() => handleNameClickProfile(listPatients?.referrer)}>
    //       <AvatarName
    //         avatar={listPatients?.referrer.avatar_url}
    //         name={listPatients?.referrer.name}
    //       />
    //     </div>
    //   ),
    // },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: string, record: any) => <SpanMailTo mail={text} />,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      render: (text: string, record: any) => <SpanPhone phone={text} />,
    },
    // {
    //   title: "Địa chỉ",
    //   dataIndex: "address",
    //   key: "address",
    // },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      render: (text: string, record: any) => (
        <span>{text === "male" ? "Nam" : text === "female" ? "Nữ" : ""}</span>
      ),
    },
    {
      title: "Tuổi",
      dataIndex: "dob",
      key: "dob",
      render: (text: string, record: any) => getAge(text),
    },
    // {
    //   title: "Ngày sinh",
    //   dataIndex: "dob",
    //   key: "dob",
    //   width: 150,
    //   render: (text: string, record: any) => moment(text).format(dateFormat),
    // },
    {
      title: "Ghi chú của bác sĩ",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Của Bác sĩ",
      dataIndex: "",
      key: "",
      render: (listPatients: any, record) => (
        <div>
          <AvatarName
            avatar={listPatients?.referrer.avatar_url}
            name={listPatients?.referrer.name}
            profileDoctor={() => handleNameClickProfile(listPatients?.referrer)}
            viewDetail={() => {
              history.push(`${path}/schedule-detail/${record.id}`);
            }}
          />
        </div>
      ),
    },
    {
      title: "Hành động",
      render: (record: any) => (
        <>
          <div>
            <IconCheckNow
              onClick={() => {
                history.push(`${path}/schedule-detail/${record.id}`);
              }}
            />
            <IconTest
              onClick={() => {
                history.push(`${path}/order-patient-test/${record.id}`);
              }}
            />
            <IconDelete
              onClick={() => {
                setOpenModalDelete(true);
                formDelete.setFieldsValue({
                  patiendId: record.id,
                  referrer_id: record.referrer,
                });
              }}
            />
          </div>
        </>
      ),
    },
    // {
    //   title: "Hành động",
    //   render: (text: string, record: any) => (
    //     <>
    //       <IconEdit
    //         onClick={() => {
    //           setIsModalUpdate(true);
    //           form.setFieldsValue({
    //             id: record.id,
    //             name: record.name,
    //             price: record.price,
    //           });
    //         }}
    //       />
    //       <IconDelete
    //         onClick={() => {
    //           setIsModalDelete(true);
    //           formDelete.setFieldsValue({
    //             id: record.id,
    //           });
    //         }}
    //       />
    //     </>
    //   ),
    // },
  ];

  function getAge(dateString: string) {
    if (!dateString) {
      return dateString;
    }
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  // const rowSelection = {
  //   selectedRowKeys: selectedRows,
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedRows(selectedRows);
  //     setSelectedRows(selectedRows.map((el) => el.id));
  //     // console.log(selectedRows);
  //   },
  //   onSelect: (record, selected, selectedRows) => {
  //     setSelectedRows(selectedRows.map((el) => el.id));
  //   },
  //   onSelectAll: (selected, selectedRows, changeRows) => {
  //     setSelectedRows(selectedRows.map((el) => el.id));
  //   },
  // };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData(pagination.current);
  };

  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "/patient-referrers", name: "Bệnh nhân được giới thiệu" },
  ];

  const handleCancel = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deletePatientReferrer(
        values.patiendId,
        values.referrer_id.id
      );
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      fetchData();
      setLoading(false);
      handleCancel();
    }
  };

  // const onConfirmDelete = () => {
  //   confirm({
  //     okText: "Delete",
  //     cancelText: "Cancel",
  //     title: "Bạn có chắc chắn muốn xóa bệnh nhân này không?",
  //     icon: <ExclamationCircleOutlined />,
  //     onOk() {
  //       selectedRows.map(async (id) => {
  //         try {
  //           setLoading(true);
  //           const response: any = await api.deletePatientReferrer(
  //             parseInt(`${id}`),
  //             user.id
  //           );
  //           if (response.error) {
  //             message.error(response.message);
  //           } else {
  //             message.success(response.message);
  //           }
  //         } catch (error) {
  //           console.log(error);
  //           message.error(error.message);
  //         } finally {
  //           setLoading(false);
  //           // fetchAllMedicines();
  //         }
  //       });
  //       setTimeout(() => {
  //         setSelectedRows([]);
  //       }, 2000);
  //     },
  //     onCancel() {
  //       console.log("Cancel delete");
  //     },
  //   });
  // };

  return (
    <div className="admin-table">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: ".5rem",
          }}
        ></div>
        <div>
          {/* <Button
            onClick={onConfirmDelete}
            disabled={selectedRows.length === 0}
            style={{
              marginRight: ".5rem",
            }}
            type="primary"
            danger
          >
            Huỷ
          </Button> */}

          <SearchInput
            onSearch={(value) => {
              setSearchValue(value);
            }}
            title="Tìm kiếm"
            width={250}
          />
        </div>

        <Table
          // rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={filteredData}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />

        <CardProfile
          doctorArr={dataProfile}
          isModalOpenProfile={isModalOpenProfile}
          setIsModalCancelProfile={() => SetIsModalOpenProfile(false)}
        />

        <Modal
          title="Xóa bệnh nhân được giới thiệu"
          open={isOpenModalDelete}
          footer={null}
          onCancel={handleCancel}
        >
          <Form form={formDelete} onFinish={handleDelete}>
            <Form.Item name="patiendId">
              <div>
                <ExclamationCircleOutlined /> Bạn có chắc chắn muốn xóa?
                <input type="number" hidden />
              </div>
            </Form.Item>
            <Form.Item name="referrer_id" hidden>
              <div>
                <input type="number" hidden />
              </div>
            </Form.Item>
            <Form.Item style={{ textAlign: "right", marginBottom: "unset" }}>
              <Button type="primary" danger loading={loading} htmlType="submit">
                Có
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}
