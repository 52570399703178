import { Card, Form, Input, Radio } from 'antd';
import React from 'react';

function PersonalInformation() {
    return (
        <Card title="Thông tin cá nhân" 
            className='personal-information'
            bordered={true}  
        >
            <Form.Item
                label="Họ và tên"
                name="name"
                rules={[
                    {
                        required: true,
                        message: "Họ & Tên không được để trống",
                        min: 2,
                        max: 191,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Giới tính"
                name="gender"
                rules={[{ required: true, message: 'Giới tính không được để trống' }]}
            >
                <Radio.Group>
                    <Radio value="male"> Nam </Radio>
                    <Radio value="female"> Nữ </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Chuyên khoa"
                name="specialist"
                rules={[{ required: true, message: 'Chuyên khoa không được để trống' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Ngày sinh"
                name="dob"
                rules={[{ required: true, message: 'Ngày sinh không được để trống' }]}
            >
                <Input type="date" />
            </Form.Item>
            <Form.Item
                label="Mã chứng chỉ hành nghề"
                name="certificate_code"
                rules={[{ required: true, message: 'Mã chứng chỉ hành nghề không được để trống' }]}
            >
                <Input />
            </Form.Item>
        </Card>
    );
}

export default PersonalInformation;