import { List, Tag, Card, Skeleton, Modal, message, Button, Spin } from "antd";
import { useState } from "react";
import { api } from "src/services";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { IconButton, ImageListItem, ImageListItemBar } from "@mui/material";
import { FaXmark } from "react-icons/fa6";

const { confirm } = Modal;

function ServicePane({ servicePatient }) {
  const [loading, setLoading] = useState(false);

  return (
    <div className="admin-table OrderTest">
      <div className="flex-fill">
        <div>
          <Card title="Kết quả:" size="small" style={{ marginBottom: 16 }}>
            <List
              dataSource={servicePatient}
              renderItem={(itemfile: any) => (
                <List.Item>
                  <Tag>
                    <a href={itemfile.url} target="_blank">
                      {itemfile.url}
                    </a>
                  </Tag>
                </List.Item>
              )}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ServicePane;
