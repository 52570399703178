import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getSignatures<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/all-signature-doctor`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function createSignature<T>(formData: FormData): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/signatures/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteSignature<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/signature-delete/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteAllSignature<T>(ids: any): NetworkPromiseResponse<T> {
  const jsonIds = JSON.stringify(ids);
  return new Promise((resolve, reject) => {
    network
      .request({
        url: "v1/signature/all",
        method: "delete",
        data: jsonIds,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function updateSignature<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/signature-update/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getSignatures,
  createSignature,
  deleteSignature,
  updateSignature,
  deleteAllSignature,
};

export default data;
