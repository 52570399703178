import { Card, Divider, Form, Select, Space, Spin } from "antd";
import { useCallback } from "react";

const CardDisease = ({
  loading,
  loadingDisease,
  setSearchQueryDisease,
  diseaseCategories,
  selectedDiseases,
  handleSelectedDiseases,
  total,
  samplePrescriptionDetail = {} as any,
}) => {
  const customFilter = useCallback((candidate, input) => {
    if (input) {
    }
    return true;
  }, []);
  return (
    <div>
      <Card
        title="Chẩn đoán"
        style={{
          marginBottom: 16,
          height: "unset",
        }}
        loading={loading}
        size="small"
      >
        <Form.Item name="type_disease">
            <Select
              mode="multiple"
              showSearch={true}
              allowClear
              style={{ width: "100%" }}
              placeholder="Vui lòng chọn"
              loading={loadingDisease}
              options={diseaseCategories.map((item) => ({
                label: (
                  <span>
                    {item.key} {item.key ? "-" : ""} {item.name}
                  </span>
                ),
                value: item.id,
              }))}
              onChange={handleSelectedDiseases}
              onPopupScroll={selectedDiseases}
              onSearch={setSearchQueryDisease}
              defaultValue={samplePrescriptionDetail.type_disease_ids}
              filterOption={customFilter}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  {loadingDisease && diseaseCategories.length < total && (
                    <div
                      style={{
                        display: "block",
                        marginTop: 10,
                        marginBottom: 10,
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: 50,
                      }}
                    >
                      <Spin />
                    </div>
                  )}
                  {diseaseCategories.length >= total && (
                    <Divider plain>
                      Hết rồi, không còn chẩn đoán nào nữa 😥
                    </Divider>
                  )}
                </div>
              )}
            />
        </Form.Item>
      </Card>
    </div>
  );
};

export default CardDisease;
