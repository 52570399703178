import { Modal, Form, Input, Button, InputNumber } from "antd";

const EditServiceModal = ({ open, onCancel, loading, handleSubmit, form }) => {
  return (
    <Modal title="Chỉnh sửa" open={open} footer={null} onCancel={onCancel}>
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item name="id" hidden>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Tên dịch vụ không được bỏ trống",
            },
          ]}
        >
          <div className="form-center">
            <label> Tên dịch vụ: </label>
            <Form.Item name="name">
              <Input type="text" placeholder="Nhập vào tên dịch vụ cần thêm" />
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item
          name="price"
          rules={[
            {
              required: true,
              message: "Giá không được để trống",
            },
          ]}
        >
          <div className="form-center">
            <label> Giá: </label>
            <Form.Item name="price">
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                placeholder="Nhập giá tiền"
              />
            </Form.Item>
          </div>
        </Form.Item>
        <Form.Item style={{ textAlign: "right", marginBottom: "unset" }}>
          <Button type="primary" loading={loading} htmlType="submit">
            Cập nhập
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditServiceModal;
