import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getHeadletter<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/all-headletter-doctor`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function createHeadletter<T>(formData: FormData): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/headletter/create`, formData , {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteHeadletter<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/headletter-delete/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteAllHeadletter<T>(ids: any): NetworkPromiseResponse<T> {
  const jsonIds = JSON.stringify(ids);
  return new Promise((resolve, reject) => {
    network
      .request({
        url: "v1/headletter/all",
        method: "delete",
        data: jsonIds,
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function updateHeadletter<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/headletter-update/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getHeadletter,
  createHeadletter,
  deleteHeadletter,
  updateHeadletter,
  deleteAllHeadletter,
};

export default data;
