import { Modal, Spin, List, Card, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { TreatmentregimenDetail } from "src/views";
import Parser from "html-react-parser";
import { BreadcrumbComponent, SearchInput } from "src/components";

export default function Treatmentregimen() {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpenDetail, SetIsModalOpenDetail] = useState(false);
  const [data, setData] = useState([]);
  const [dataContent, setDataContent] = useState<any>([]);
  const [page, setPage] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(30);
  const [total, setTotal] = useState<any>("");

  useEffect(() => {
    fetchData();
  }, [searchValue, page, pageSize]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: rPatitent, meta }: any = await api.getAllTreatmentregimen(
        pageSize,
        page,
        searchValue
      );
      if (rPatitent) {
        setData(rPatitent);
        setTotal(meta.total);
        setLoading(false);
      } else {
        // message.error("Không tìm thấy dữ liệu");
        setData([]);
        setTotal(0);
        setPageSize(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNameClick = async (content) => {
    SetIsModalOpenDetail(true);
    setDataContent(Parser(content));
  };

  const handleCancelDetail = () => {
    SetIsModalOpenDetail(false);
  };

  const handleTableChange = (page?: any, pageSize?: any) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const gridStyle: React.CSSProperties = {
    width: "100%",
    padding: "0",
    cursor: "pointer",
  };

  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "#", name: "Phác đồ điều trị" },
  ];

  return (
    <div className="admin-table dictionary">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: ".5rem",
          }}
        ></div>
        <div>
          <div>
            <SearchInput
              onSearch={(value) => {
                setSearchValue(value);
              }}
              title={"Tìm kiếm"}
              width={250}
            />
          </div>
        </div>
        <List
          pagination={{
            onChange: handleTableChange,
            current: page,
            pageSize: pageSize,
            total: total,
          }}
          loading={loading}
          grid={{
            gutter: 16,
            column: 3,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Skeleton loading={loading} active>
                <Card.Grid
                  style={gridStyle}
                  onClick={() => handleNameClick(item.content)}
                >
                  <Card className="dictionary__card" title={item.name}>
                    <div
                      className="dictionary-details"
                      style={{ height: "90px", overflow: "hidden" }}
                    >
                      {Parser(item.content)}
                    </div>
                  </Card>
                </Card.Grid>
              </Skeleton>
            </List.Item>
          )}
        />
        <Modal
          title="Phác đồ điều trị chi tiết chi tiết"
          centered
          open={isModalOpenDetail}
          onOk={handleCancelDetail}
          onCancel={() => SetIsModalOpenDetail(false)}
          footer={null}
          // width={1300}
        >
          <Spin spinning={loading}>
            <TreatmentregimenDetail content={dataContent} />
          </Spin>
        </Modal>
      </div>
    </div>
  );
}
