import { Modal, Form, Input, Button } from "antd";

const PaymentConfirmationModal = ({
  isVisible,
  onCancel,
  onFinishUpdate,
  loading,
  form,
}) => {
  const handleUpdate = (values) => {
    onFinishUpdate(values);
  };

  return (
    <Modal
      title="Xác nhận thanh toán"
      open={isVisible}
      footer={null}
      onCancel={onCancel}
    >
      <Form onFinish={handleUpdate} form={form}>
        <Form.Item name="id" hidden>
          <Input type="text" />
        </Form.Item>
        <label>Bạn có chắc chắn rằng bệnh nhân này đã thanh toán?</label>
        <Form.Item style={{ textAlign: "right", marginBottom: "unset" }}>
          <Button type="primary" loading={loading} htmlType="submit">
            Có
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PaymentConfirmationModal;
