import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getAllSubclinicals<T>(id: number): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/subclinicals/all-category/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getSubclinicals<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/subclinicals/all-category-subclinicals`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getSubclinicalsDetail<T>(
  id: number,
  patient_id: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(
        `v1/subclinicals/all-subclinical-by-cate-and-patient/${id}?patient_id=${patient_id}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function SubclinicalsAdd<T>(formData: FormData): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/subclinicals/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deleteSubclinicals<T>(
  file_url: string,
  type: string,
  id: number,
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(
        `v1/subclinicals/delete/${id}?file_url=${file_url}&type=${type}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getAllSubclinicals,
  getSubclinicalsDetail,
  getSubclinicals,
  SubclinicalsAdd,
  deleteSubclinicals,
};

export default data;
