import { List, Card, Skeleton } from "antd";
import { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

function ClinicalNotesDetai(props) {
  const [loading, setLoading] = useState(false);
  const { rPatitent } = props;

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <div className="admin-table">
      <div className="flex-fill">
        <div>
          <div>
            <h3>Kết quả khám: </h3>
            {rPatitent.examination_results ? (
              <p>{rPatitent.examination_results}</p>
            ) : (
              "Không tìm thấy thông tin"
            )}
          </div>

          <div>
            <h3>Chỉ định xét nghiệm: </h3>
            {rPatitent.type_tests ? (
              <List
                dataSource={rPatitent.type_tests}
                renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
              />
            ) : (
              "Không tìm thấy thông tin"
            )}
          </div>

          <div>
            <h3>Chuẩn đoán: </h3>
            {rPatitent.type_diseases ? (
              <List
                dataSource={rPatitent.type_diseases}
                renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
              />
            ) : (
              "Không tìm thấy thông tin"
            )}
          </div>

          {rPatitent.note ? (
            <div>
              <h3>Ghi chú thêm:</h3>
              <p>{rPatitent.note}</p>
            </div>
          ) : (
            ""
          )}
          {rPatitent.images ? (
            <div>
              <Card
                size="small"
                style={{
                  marginBottom: 16,
                  height: "unset",
                }}
                title="Ảnh:"
              >
                <Skeleton loading={loading}>
                  <div>
                    <ImageList
                      variant="quilted"
                      cols={Math.min(rPatitent.images.length, 5)}
                      gap={16}
                      rowHeight={
                        rPatitent.images.length === 4
                          ? 150
                          : rPatitent.images.length === 3
                          ? 200
                          : rPatitent.images.length === 2
                          ? 250
                          : rPatitent.images.length === 1
                          ? 500
                          : 121
                      }
                    >
                      {rPatitent.images.map((item: any) => (
                        <div>
                          {item ? (
                            <ImageListItem
                              key={item}
                              cols={item.cols || 1}
                              rows={item.rows || 1}
                              className="list-images"
                            >
                              <img
                                {...srcset(item, 121, item.cols, item.rows)}
                                alt={item}
                                loading="lazy"
                                onClick={() => window.open(item)}
                              />
                            </ImageListItem>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </ImageList>
                  </div>
                </Skeleton>
              </Card>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ClinicalNotesDetai;
