import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getProfile<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get("v1/me")
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function updateProfile<T>(formData: FormData): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/me", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function createDoctorReferrers<T>(
  formData: FormData
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/doctor-referrers/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function changeAvatar<T>(formData: FormData): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/update/avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function login<T>(formData: {
  phone: string;
  password: string;
}): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/login", formData)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function logout<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get("v1/logout")
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function resetPassword<T>(formData: {
  phone: string;
}): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/password/forgot", formData)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function verifyOtpPassword<T>(formData: {
  token: string;
  code: string;
}): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/password/verify-otp", formData)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function updatePassword<T>(formData: {
  token: string;
  password: string;
  password_confirmation: string;
}): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post("v1/password/reset", formData)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function changePassword<T>(formData): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .put("v1/update/password", formData)
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function deleteAccount<T>(phone): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/delete-account/${phone}`)
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getProvinces<T>(
  province_id: string,
  district_id: string
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/provinces?province_id=${province_id}&district_id=${district_id}`)
      .then((res) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

const data = {
  getProfile,
  login,
  logout,
  resetPassword,
  verifyOtpPassword,
  updatePassword,
  updateProfile,
  createDoctorReferrers,
  changePassword,
  changeAvatar,
  deleteAccount,
  getProvinces,
};

export default data;
