import { combineReducers } from "redux";
// import {persistReducer} from 'redux-persist';
// import AsyncStorage from '@react-native-async-storage/async-storage';
import user from "../user/reducer";
// import first from '../first/reducer';
// import token from '../token/reducer';
// import schedule_patient from '../schedule_patient/reducer';
import setting from '../setting/reducer';

const reducer = combineReducers({
  user,
  // token,
  // first,
  // schedule_patient,
  setting,
});

// const persistConfig = {
//   key: "root",
//   storage: AsyncStorage,
//   whitelist: ["first", "token"],
// };

// const reducer = persistReducer(persistConfig, reducers);

export default reducer;
