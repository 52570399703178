import { Button, Card, Col, Form, Input, InputNumber, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import CardSave from "../shared/Card/CardSave";

const UpdateMedicineModal = ({
  isModalUpdateVisible,
  handleCancel,
  handleUpdate,
  formUpdate,
  loading,
}) => {
  return (
    <Modal
      title="Chỉnh sửa thuốc"
      open={isModalUpdateVisible}
      footer={null}
      onCancel={handleCancel}
      width={1200}
    >
      <Form onFinish={handleUpdate} form={formUpdate}>
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <Card
              size="small"
              style={{
                marginBottom: 16,
                height: "unset",
                padding: "1rem 1rem 0 1rem",
              }}
              loading={loading}
            >
              <Form.Item name="id" style={{ display: "none" }}>
                <div>
                  <Input type="text" hidden />
                </div>
              </Form.Item>
              <div className="wrap-medicine">
                <Form.Item
                  label="Tên Thuốc"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Tên thuốc không được bỏ trống",
                      min: 2,
                      max: 191,
                    },
                  ]}
                >
                  <Input placeholder="Tên thuôc" />
                </Form.Item>
                <Form.Item
                  label="Đơn vị"
                  name="unit"
                  rules={[
                    {
                      required: true,
                      message: "Đơn vị không được để trống",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Đơn vị" />
                </Form.Item>
                <Form.Item
                  label="Cách dùng"
                  name="drug_route"
                  rules={[
                    {
                      required: true,
                      message: "Cách dùng không được để trống",
                    },
                  ]}
                >
                  <Input type="text" placeholder="Cách dùng" />
                </Form.Item>
                <Form.Item
                  label="Số lượng tồn"
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "Số lượng không được để trống",
                    },
                  ]}
                >
                  <Input type="number" placeholder="0" />
                </Form.Item>
                <Form.Item
                  label="Giá nhập"
                  name="cost_of_goods_sold"
                  rules={[
                    {
                      required: true,
                      message: "Giá nhập không được để trống",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    placeholder="Nhập giá tiền"
                  />
                </Form.Item>
                <Form.Item
                  label="Giá xuất"
                  name="cost_price"
                  rules={[
                    {
                      required: true,
                      message: "Giá xuất không được để trống",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    placeholder="Nhập giá tiền"
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Mô tả không được bỏ trống",
                  },
                ]}
              >
                <TextArea
                  showCount
                  maxLength={500}
                  rows={4}
                  // onChange={onChange}
                  placeholder="Mô tả thuốc"
                />
              </Form.Item>
            </Card>
          </Col>

          <Col span={6}>
            <CardSave loading={loading} />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateMedicineModal;
