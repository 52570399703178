import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getAllTreatmentregimen<T>(
  per_page: number,
  page:number,
  q:string,
  ): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/treatment-regimen/all?per_page=${per_page}&page=${page}&q=${q}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getTreatmentregimenDetail<T>(
  id: number,
  ): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/treatment-regimen/${id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getAllTreatmentregimen,
  getTreatmentregimenDetail,
};

export default data;
