import { Card, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { api } from 'src/services';


function ContactInformation({ province, district, formRef }) {
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [provinceSelected, setProvinceSelected] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchProvinces();
    }, []);

    const fetchProvinces = async () => {
        setLoading(true);
        const res: any = await api.getProvinces(province?.matp, district?.maqh);
        setLoading(false);
        if (res && res.data && res.data.province) {
            res.data.province = res.data.province.map((item) => ({
                value: item?.matp,
                label: item?.name
            }));
            setProvinces(res.data.province);
            setDistricts(res.data.district.map((item) => ({
                value: item?.maqh,
                label: item?.name
            })));
            setWards(res.data.ward.map((item) => ({
                value: item?.xaid,
                label: item?.name
            })));
        }
    }

    const handleOnChangeProvince = async (province_id) => {
        formRef.current.setFieldValue("district_id", []);
        setProvinceSelected(province_id);
        setLoading(true);
        const res: any = await api.getProvinces(province_id, '');
        setLoading(false);
        if (res && res.data && res.data.district) {
            res.data.district = res.data.district.map((item) => ({
                value: item?.maqh,
                label: item?.name
            }));
            setDistricts(res.data.district);
        }
    }

    const handleOnChangeDistrict = async (district_id) => {
        formRef.current.setFieldValue("ward_id", []);
        setLoading(true);
        const res: any = await api.getProvinces(provinceSelected, district_id);
        setLoading(false);
        if (res && res.data && res.data.ward) {
            res.data.ward = res.data.ward.map((item) => ({
                value: item?.xaid,
                label: item?.name
            }));
            setWards(res.data.ward);
        }
    }

    return (
        <Card title="Thông tin liên hệ" bordered={true} className='contact-information'>
            <Form.Item
                label="Tên phòng khám"
                name="hospital_name"
                rules={[{ required: true, message: 'Tên phòng khám không được để trống' }]}
            >
                <Input />
            </Form.Item>
            {provinces ? (
                <Form.Item
                    label="Thành phố / tỉnh"
                    name="province_id"
                    rules={[{ required: true, message: 'Thành phố / tỉnh không được để trống' }]}
                >
                    <Select
                        loading={loading}
                        showSearch
                        placeholder="Chọn Thành phố / Tỉnh"
                        optionFilterProp="children"
                        onSelect={(province_id) => handleOnChangeProvince(province_id)}
                        filterOption={(input: any, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        filterSort={(optionA: any, optionB: any): any =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={provinces}
                    />
                </Form.Item>
            ) : ""}

            {districts ? (
                <Form.Item
                    label="Quận / Huyện"
                    name="district_id"
                    rules={[{ required: true, message: 'Quận / Huyện không được để trống' }]}
                >
                    <Select
                        loading={loading}
                        showSearch
                        placeholder="Chọn Quận / Huyện"
                        optionFilterProp="children"
                        onSelect={(district_id) => handleOnChangeDistrict(district_id)}
                        filterOption={(input: any, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        filterSort={(optionA: any, optionB: any): any =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={districts}
                    />
                </Form.Item>
            ) : ""}

            {wards ? (
                <Form.Item
                    label="Xã phường"
                    name="ward_id"
                    rules={[{ required: true, message: 'xã phường không được để trống' }]}
                >
                    <Select
                        loading={loading}
                        showSearch
                        placeholder="Chọn xã phường"
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        filterSort={(optionA: any, optionB: any): any =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={wards}
                    />
                </Form.Item>
            ) : ""}

            <Form.Item
                label="Địa chỉ"
                name="short_address"
                rules={[{ required: true, message: 'Địa chỉ không được để trống' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Email không được để trống' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Số điện thoại"
                name="phone"
                rules={[{ required: true, message: 'Số điện thoại không được để trống' }]}
            >
                <Input disabled />
            </Form.Item>
        </Card>
    );
}
export default ContactInformation;