import { Card, Col, Form, Input, List, Modal, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { utils } from 'src/utils';

function DetailSamplePrescriptionModal({
    isModalUpdateVisible,
    handleCancel,
    samplePrescriptionDetail
}) {
    return (
        <Modal
            title="Thông tin chi tiết toa thuốc mẫu"
            open={isModalUpdateVisible}
            footer={null}
            onCancel={handleCancel}
            width={1400}
            centered
            className='sample-prescription-add'
        >
            <Form
                className='form-add-prescription'
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card
                            className="card-date-note"
                            size="small"
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            <Form.Item
                                label="Tên toa thuốc mẫu"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tên toa thuốc mẫu không được để trống",
                                    },
                                ]}
                            >
                                <Input
                                    readOnly
                                    defaultValue={samplePrescriptionDetail.name}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Ghi chú"
                                name="note"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vui lòng nhập ghi chú!",
                                    },
                                ]}
                            >
                                <TextArea
                                    showCount
                                    maxLength={500}
                                    rows={4}
                                    placeholder="Ghi chú"
                                    readOnly
                                    defaultValue={samplePrescriptionDetail.note}
                                />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card
                            title="Chẩn đoán"
                            style={{
                                marginBottom: 16,
                                height: "unset",
                            }}
                            size="small"
                        >
                            <Space style={{ width: "100%" }} direction="vertical">
                                <List
                                    header={null}
                                    footer={null}
                                    size="small"
                                    dataSource={samplePrescriptionDetail?.type_disease}
                                    renderItem={(item: any) => (
                                        <List.Item>{item?.name}</List.Item>
                                    )}
                                />
                            </Space>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <table className="table table-striped table_add_prescription">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tên thuốc</th>
                                    <th>Cách dùng</th>
                                    <th>Số lượng</th> 
                                    <th>Ngày</th>
                                    <th>Sáng</th>
                                    <th>Trưa</th>
                                    <th>Chiều</th>
                                    <th>Tối</th>
                                    <th>Đơn giá</th>
                                </tr>
                            </thead>
                            <tbody>
                                {samplePrescriptionDetail && samplePrescriptionDetail.prescriptions &&
                                    samplePrescriptionDetail.prescriptions.map((prescription, index) => (
                                        <tr>
                                            <td>{++index}</td>
                                            <td>{ 8 in prescription ? prescription[8] : ""}</td>
                                            <td>{ 1 in prescription ? prescription[1]['value'] : "" }</td>
                                            <td>{ 2 in prescription ? prescription[2]['value'] : ""  }</td>
                                            <td>{ 3 in prescription ? prescription[3]['value'] : ""  }</td>
                                            <td>{ 4 in prescription ? prescription[4]['value'] : ""  }</td>
                                            <td>{ 7 in prescription ? prescription[7]['value'] : ""  }</td>
                                            <td>{ 5 in prescription ? prescription[5]['value'] : ""  }</td>
                                            <td>{ 6 in prescription ? prescription[6]['value'] : ""  }</td>
                                            <td>{ 9 in prescription ? utils.formatMoney(prescription[9]['value']) : "" } đ</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div className="totalPrice">
                            <h4>Tổng tiền:</h4>
                            <span>{samplePrescriptionDetail?.total_format}</span>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default DetailSamplePrescriptionModal;