import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  message,
} from "antd";
import locale from "antd/lib/date-picker/locale/vi_VN";
import _ from "lodash";
import { useRef, useState } from "react";
import { api } from "src/services";
import { utils } from "src/utils";

const dateFormat = "DD/MM/YYYY";

export default function AddSignsAllergy({
  patient,
  params,
  getPatient,
  setIsOpenModalAdd,
}) {
  const [loading, setLoading] = useState(false);
  const {
    name,
    history,
    unit,
    title,
    hideHistory,
    min,
    max,
    type,
    alertValue,
  } = params;
  const [isAlert, setIsAlert] = useState(false);
  const [fnum, setFnum] = useState(0);
  const [snum, setSnum] = useState(0);
  const formRef = useRef<any>(null);
  const [form] = Form.useForm();
  const configReExaminationDate = {
    rules: [
      {
        type: "object" as const,
        required: true,
        message: "Vui lòng chọn ngày!",
      },
    ],
  };
  const inputRefDigit = useRef(null);
  const inputRef2Digit = useRef(null);

  const handleSubmit = async (values) => {
    try {
      let val: any = {};
      let foramtValues = {
        value:
          type !== "2digits"
            ? parseFloat(values[name])
            : {
              fnum: parseFloat(values.fnum.toString()),
              snum: parseFloat(values.snum.toString()),
            },
        date: values.date,
      };
      val[name] =
        type !== "textarea" && type !== "text"
          ? JSON.stringify([...(history || []), foramtValues])
          : values[name];
      val["member_id"] = patient?.id;
      if (patient?.sign_allergy?.id) {
        const res: any = await api.updateSigns(patient?.sign_allergy?.id, val);
        setLoading(true);
        if (res.data) {
          getPatient(patient?.id);
          form.resetFields();
          formRef.current.resetFields();
          setIsOpenModalAdd(false);
          setLoading(false);
          message.success(`Cập nhật ${title} thành công!`);
        }
      } else {
        const res: any = await api.createSigns(val);
        setLoading(true);
        if (res.data) {
          getPatient(patient?.id);
          form.resetFields();
          formRef.current.resetFields();
          setIsOpenModalAdd(false);
          setLoading(false);
          message.success(`Thêm ${title} thành công!`);
        }
      }
    } catch (error: any) {
      console.log(error);
      if (error?.data?.error) {
        message.error(`Thêm hoặc cập nhật ${title} thất bại!`);
      }
      setLoading(false);
    }
  };

  return (
    <Form
      ref={formRef}
      wrapperCol={{ span: 24 }}
      onFinish={handleSubmit}
      className="form_signs"
      autoComplete="off"
      onFieldsChange={(e) => {
        if (_.get(e, "0.name.0") === "fnum") {
          setFnum(_.get(e, "0.value", 0));
        }
        if (_.get(e, "0.name.0") === "snum") {
          setSnum(_.get(e, "0.value", 0));
        }
      }}
    >
      {type === "digit" ? (
        <Form.Item
          label={title}
          name={name}
          rules={[
            { required: true, message: `${title} không được để trống` },
            {
              validator: async (_, value) => {
                if (value < min && value !== null) {
                  return Promise.reject(
                    new Error(`${title} phải lớn hơn ${min}${unit}`)
                  );
                }
                if (value > max) {
                  return Promise.reject(
                    new Error(`${title} phải nhỏ hơn ${max}${unit}`)
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber
            ref={inputRefDigit}
            type="number"
            style={{ width: "100%" }}
            addonAfter={unit}
            onInput={(value : string) => inputRefDigit.current.value = Math.abs(parseInt(value))}
            onChange={(value: string) => {
              if (!!alertValue && parseInt(value) >= parseInt(alertValue)) {
                if (!isAlert) {
                  Modal.error({
                    title: `Cảnh báo ${title} lớn hơn ${alertValue}${unit}`,
                    content:
                      "Tình trạng sức khoẻ của bạn không tốt. Cần gặp bác sĩ để kiểm tra!",
                  });
                  setIsAlert(true);
                }
              }
            }}
          />
        </Form.Item>
      ) : type === "2digits" ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            style={{ flexBasis: "45%" }}
            name="fnum"
            rules={[
              { required: true, message: `Không được để trống số đầu` },
              {
                validator: async (_, value) => {
                  if (parseInt(value) <= 0) {
                    return Promise.reject(new Error(`Vui lòng nhập chính xác số trước`));
                  }
                  if (parseFloat(value) < snum) {
                    return Promise.reject(new Error(`Số trước phải lớn hơn số sau`));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              min={0}
              type="number"
              style={{ width: "100%" }}
              placeholder="Nhập số đầu"
              onInput={(e: any) => e.target.value = Math.abs(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            style={{ flexBasis: "55%" }}
            name="snum"
            rules={[
              { required: true, message: `Không được để trống số sau` },
              {
                validator: async (_, value) => {
                  if (parseInt(value) <= 0) {
                    return Promise.reject(new Error(`Vui lòng nhập chính xác số sau`));
                  }
                  if (parseFloat(value) > fnum) {
                    return Promise.reject(new Error(`Số sau phải nhỏ hơn số trước`));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              ref={inputRef2Digit}
              min={1}
              type="number"
              style={{ width: "100%" }}
              placeholder="Nhập số sau"
              onInput={(value : string) => inputRef2Digit.current.value = Math.abs(parseInt(value))}
              addonAfter={unit}
            />
          </Form.Item>
        </div>
      ) : (
        <>
          {type === "text" ? (
            <Form.Item
              name={name}
              rules={[
                { required: true, message: `${title} không được bỏ trống` },
                {
                  pattern: /^([AaBbOo]|[Aa][Bb])[\+-]$/,
                  message:
                    "Nhóm máu phải thuộc A+, A-, B+, B-, AB+, AB-, O+, O-",
                },
              ]}
            >
              <Input
                onInput={(e: any) =>
                  (e.target.value = e.target.value.toUpperCase())
                }
                placeholder={`Nhập ${title}`}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name={name}
              rules={[
                { required: true, message: `${title} không được bỏ trống` },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={500}
                style={{ marginBottom: "1rem" }}
                rows={8}
              />
            </Form.Item>
          )}
        </>
      )}
      {type !== "textarea" && type !== "text" ? (
        <Form.Item label="Ngày khám" name="date" {...configReExaminationDate}>
          <DatePicker
            locale={locale}
            format={dateFormat}
            placeholder="Chọn ngày khám"
            style={{ width: "100%" }}
          />
        </Form.Item>
      ) : null}
      <Button
        type="primary"
        style={{ width: "100%" }}
        htmlType="submit"
        loading={loading}
      >
        Cập nhật
      </Button>
      {!hideHistory ? (
        <>
          <Divider orientation="left">
            Danh sách lịch sử cập nhật {title}
          </Divider>
          <List
            header={
              <div style={{ fontWeight: "500" }}>
                Lịch sử cập nhật <span style={{ float: "right" }}>{title}</span>
              </div>
            }
            bordered
            dataSource={history}
            renderItem={(item) => (
              <List.Item>
                <span>{utils.formatDate((item as { date: Date })?.date)}</span>
                {type !== "2digits" ? (
                  <span>
                    {(item as { value: number })?.value} {unit}
                  </span>
                ) : (
                  <span>
                    {`${(item as { value: { fnum?: number; snum?: number } })
                        ?.value?.fnum || 0
                      }/${(item as { value: { fnum?: number; snum?: number } })
                        ?.value?.snum || 0
                      }`}{" "}
                    {unit}
                  </span>
                )}
              </List.Item>
            )}
          />
        </>
      ) : null}
    </Form>
  );
}
