import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { allActions } from "src/redux";
import { api } from "src/services";

function PasswordReset(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneVerifyToken, setPhoneVerifyToken] = useState<string>("");
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = async (values: { phone: string }) => {
    try {
      setLoading(true);
      const response: { data: any } = await api.resetPassword(values);
      const { phone_verify_token = null } = response?.data;
      if (phone_verify_token) {
        setPhoneVerifyToken(phone_verify_token);
      } else {
        message.error("Data invalid!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  const onFinishOtp = async (values: { code: string }) => {
    try {
      setLoading(true);
      const response: { data: any } = await api.verifyOtpPassword({
        code: values.code,
        token: phoneVerifyToken,
      });
      const { phone_verify_token = null } = response?.data;
      if (phone_verify_token) {
        setPhoneVerifyToken(phone_verify_token);
        setIsChangePassword(true);
      } else {
        message.error("Data invalid!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    } 
  };

  const onFinishUpdate = async (values: {
    password: string;
    password_confirmation: string;
  }) => {
    try {
      setLoading(true);
      const response: { data: any } = await api.updatePassword({
        token: phoneVerifyToken,
        password: values.password,
        password_confirmation: values.password_confirmation,
      });
      const {
        accessToken = null,
        userData = null,
        message: msg = null,
      } = response?.data;
      if (accessToken && userData) {
        message.success(msg ? msg : "Login successfully!");

        localStorage.setItem("access_token", accessToken);

        dispatch(allActions.user.saveUser(userData));

        history.push("/");
      } else {
        message.error("Data invalid!");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  };

  return (
    <div className="auth-box">
      <h6>
        <p>FORGOT PASSWORD:</p>
      </h6>
      {!phoneVerifyToken ? (
        <>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Form.Item
              label="Username"
              name="phone"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder="Username" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={loading} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Link
            style={{
              fontSize: 12,
            }}
            to="/auth/login"
          >
            Back to login page
          </Link>
        </>
      ) : isChangePassword ? (
        <>
          <Form form={form} onFinish={onFinishUpdate} layout="vertical">
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              label="Password confirmation"
              name="password_confirmation"
              rules={[
                {
                  required: true,
                  message: "Please input your password confirmation!",
                },
              ]}
            >
              <Input.Password placeholder="Password confirmation" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={loading} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <Form form={form} onFinish={onFinishOtp} layout="vertical">
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, message: "Please input your code!" }]}
            >
              <Input placeholder="Code" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" loading={loading} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
}

export default PasswordReset;
